import { useState, useRef } from 'react'
import { Collapse, Card } from 'reactstrap';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { Grid } from '@material-ui/core';


const Subcard = ({ prop, title }) => {
  const match = prop
  const [isOpen, setIsOpen] = useState(true);
  const oddRef = useRef(0)
  const toggle = () => setIsOpen(!isOpen);


  const RoundOdd = (data) => {
    const value = Number(data)
    const rounded = value.toFixed(2)
    oddRef.current = rounded
  }

  return (
    <>
      {title === 'Winner' &&
        <Grid>
          <Card className='subcard'>
            <button onClick={toggle} className="visibility_btn">{isOpen ?
              <VisibilityIcon style={{ width: "15px", height: "15px" }} /> :
              <VisibilityOffIcon style={{ width: "15px", height: "15px" }} />}
            </button>
            <div>
              <h4 className="subcard_title">Vencedor</h4>
            </div>
            {match != null &&
              <Collapse isOpen={match.winnerft ? isOpen : !isOpen}>
                {match.winnerft ?
                <table>
                  <tbody>
                    <tr className='table_header'>
                      <th aria-hidden="true"></th>
                      <th>Odd</th>
                      <th>Últimos 10 </th>
                      <th>Todos</th>
                    </tr>
                    <tr>
                      {match.home.name &&
                        <td>{match.home.name}</td>}
                          {match.winnerft.home.odd ?
                            <td onLoad={RoundOdd(match.winnerft.home.odd)}>{oddRef.current}</td> :
                            <td className="low_chance"> --</td>}
                          {(match.winnerft.home.lastten && match.winnerft.home.lastten != 0 ) ?
                            <td
                              {...Number(match.winnerft.home.lastten) * 100 < 60 ?
                                { className: "low_chance" } :
                                Number(match.winnerft.home.lastten) * 100 <=  69 ?
                                  { className: "medium_chance" } :
                                  { className: "high_chance" }}>
                              {(match.winnerft.home.lastten * 100).toFixed(0)}%</td> :
                            <td className="low_chance"> --</td>}
                          {(match.winnerft.home.all && match.winnerft.home.all != 0) ?
                            <td
                              {...match.winnerft.home.all * 100 < 60 ?
                                { className: "low_chance" } :
                                match.winnerft.home.all * 100 <= 69 ?
                                  { className: "medium_chance" } :
                                  { className: "high_chance" }}>
                              {(match.winnerft.home.all * 100).toFixed(0)}%</td> :
                            <td className="low_chance"> --</td>}
                    </tr>
                    <tr>
                      {match.away.name &&
                        <td>{match.away.name}</td>}
                          {match.winnerft.away.odd ?
                            <td onLoad={RoundOdd(match.winnerft.away.odd)}>{oddRef.current}</td> :
                            <td className="low_chance"> --</td>
                          }
                          {(match.winnerft.away.lastten && match.winnerft.away.lastten != 0 ) ?
                            <td
                              {...match.winnerft.away.lastten * 100 < 60 ?
                                { className: "low_chance" } :
                                match.winnerft.away.lastten * 100 <=  69 ?
                                  { className: "medium_chance" } :
                                  { className: "high_chance" }}>
                              {(match.winnerft.away.lastten * 100).toFixed(0)}%</td> :
                            <td className="low_chance"> --</td>}
                          {(match.winnerft.away.all && match.winnerft.away.all != 0) ?
                            <td
                              {...match.winnerft.away.all * 100 < 60 ?
                                { className: "low_chance" } :
                                match.winnerft.away.all * 100 <= 69 ?
                                  { className: "medium_chance" } :
                                  { className: "high_chance" }}>
                              {(match.winnerft.away.all * 100).toFixed(0)}%</td> :
                            <td className="low_chance"> --</td>}
                    </tr>
                    <tr>
                      <td>Empate</td>
                          {(match.winnerft.draw.odd && match.winnerft.draw.odd != 0) ?
                            <td onLoad={RoundOdd(match.winnerft.draw.odd)}>{oddRef.current}</td> :
                            <td className="low_chance"> --</td>}
                          {(match.winnerft.draw.lastten && match.winnerft.draw.lastten != 0) ?
                            <td
                              {...match.winnerft.draw.lastten * 100 < 60 ?
                                { className: "low_chance" } :
                                match.winnerft.draw.lastten * 100 <= 69 ?
                                  { className: "medium_chance" } :
                                  { className: "high_chance" }}>
                              {(match.winnerft.draw.lastten * 100).toFixed(0)}%</td> :
                            <td className="low_chance"> --</td>}
                          {(match.winnerft.draw.all && match.winnerft.draw.all != 0) ?
                            <td
                              {...match.winnerft.draw.all * 100 < 60 ?
                                { className: "low_chance" } :
                                match.winnerft.draw.all * 100 <=  69 ?
                                  { className: "medium_chance" } :
                                  { className: "high_chance" }}>
                              {(match.winnerft.draw.all * 100).toFixed(0)} % </td> :
                            <td className="low_chance"> --</td>}
                    </tr>
                  </tbody>
                </table>: 
                 <h2 className='closedMarket' >Mercado Fechado</h2>}

              </Collapse>}
          </Card>
        </Grid>
      }
      {title === 'Hipótese' &&
        <Grid>
          <Card className='subcard'>
            <button onClick={toggle} className="visibility_btn">{isOpen ?
              <VisibilityIcon style={{ width: "15px", height: "15px" }} /> :
              <VisibilityOffIcon style={{ width: "15px", height: "15px" }} />}
            </button>
            <div>
              <h4 className="subcard_title">Ambas Marcam HT</h4>
            </div>
            {match != null &&
              <Collapse isOpen={match.bothscoreht ? isOpen : !isOpen}>
                {match.bothscoreht != null   ?
                <table>
                  <tbody>
                    <tr className='table_header'>
                      <th aria-hidden="true" />
                      <th>Odd</th>
                      <th>Últimos 10 </th>
                      <th>Geral</th>
                    </tr>
                    <tr>
                      <td>Sim</td>

                          {match.bothscoreht.yes.odd ?
                            <td onLoad={RoundOdd(match.bothscoreht.yes.odd)}>{oddRef.current}</td> :
                            <td className="low_chance"> --</td>
                          }
                          {(match.bothscoreht.yes.lastten && match.bothscoreht.yes.lastten != 0) ?
                            <td
                              {...match.bothscoreht.yes.lastten * 100 < 60 ?
                                { className: "low_chance" } :
                                match.bothscoreht.yes.lastten * 100 <= 69 ?
                                  { className: "medium_chance" } :
                                  { className: "high_chance" }}>
                              {(match.bothscoreht.yes.lastten * 100).toFixed(0)}%</td> :
                            <td className="low_chance"> --</td>}
                          {(match.bothscoreht.yes.all && match.bothscoreht.yes.all != 0) ?
                            <td
                              {...match.bothscoreht.yes.all * 100 < 60 ?
                                { className: "low_chance" } :
                                match.bothscoreht.yes.all * 100 <= 69 ?
                                  { className: "medium_chance" } :
                                  { className: "high_chance" }}>
                              {(match.bothscoreht.yes.all * 100).toFixed(0)}%</td> :
                            <td className="low_chance"> --</td>}
                    </tr>
                    <tr>
                      <td>Não</td>

                          {match.bothscoreht.no.odd ?
                            <td onLoad={RoundOdd(match.bothscoreht.no.odd)}>{oddRef.current}</td> :
                            <td className="low_chance"> --</td>
                          }
                          {(match.bothscoreht.no.lastten && match.bothscoreht.no.lastten != 0) ?
                            <td
                              {...match.bothscoreht.no.lastten * 100 < 60 ?
                                { className: "low_chance" } :
                                match.bothscoreht.no.lastten * 100 <= 69 ?
                                  { className: "medium_chance" } :
                                  { className: "high_chance" }}>
                              {(match.bothscoreht.no.lastten * 100).toFixed(0)}%</td> :
                            <td className="low_chance"> --</td>}
                          {(match.bothscoreht.no.all && match.bothscoreht.no.all != 0) ?
                            <td
                              {...match.bothscoreht.no.all * 100 < 60 ?
                                { className: "low_chance" } :
                                match.bothscoreht.no.all * 100 <= 69 ?
                                  { className: "medium_chance" } :
                                  { className: "high_chance" }}>
                              {(match.bothscoreht.no.all * 100).toFixed(0)}%</td> :
                            <td className="low_chance"> --</td>}
                    </tr>
                  </tbody>
                </table> : 
                <h2 className='closedMarket' >Mercado Fechado</h2>
                }
              </Collapse>}
          </Card>
        </Grid>
      }
      {title === 'Ambas Marcam' &&
        <Grid>
          <Card className='subcard'>
            <button onClick={toggle} className="visibility_btn">{isOpen ?
              <VisibilityIcon style={{ width: "15px", height: "15px" }} /> :
              <VisibilityOffIcon style={{ width: "15px", height: "15px" }} />}
            </button>
            <div>
              <h4 className="subcard_title">Ambas Marcam FT</h4>
            </div>
            {match != null &&
              <Collapse isOpen={match.bothscore ? isOpen : !isOpen}>
                {match.bothscore != null   ?
                <table>
                  <tbody>
                    <tr className='table_header'>
                      <th aria-hidden="true" />
                      <th>Odd</th>
                      <th>Últimos 10 </th>
                      <th>Geral</th>
                    </tr>
                    <tr>
                      <td>Sim</td>

                          {match.bothscore.yes.odd ?
                            <td onLoad={RoundOdd(match.bothscore.yes.odd)}>{oddRef.current}</td> :
                            <td className="low_chance"> --</td>
                          }
                          {(match.bothscore.yes.lastten && match.bothscore.yes.lastten != 0) ?
                            <td
                              {...match.bothscore.yes.lastten * 100 < 60 ?
                                { className: "low_chance" } :
                                match.bothscore.yes.lastten * 100 <= 69 ?
                                  { className: "medium_chance" } :
                                  { className: "high_chance" }}>
                              {(match.bothscore.yes.lastten * 100).toFixed(0)}%</td> :
                            <td className="low_chance"> --</td>}
                          {(match.bothscore.yes.all && match.bothscore.yes.all != 0) ?
                            <td
                              {...match.bothscore.yes.all * 100 < 60 ?
                                { className: "low_chance" } :
                                match.bothscore.yes.all * 100 <= 69 ?
                                  { className: "medium_chance" } :
                                  { className: "high_chance" }}>
                              {(match.bothscore.yes.all * 100).toFixed(0)}%</td> :
                            <td className="low_chance"> --</td>}
                    </tr>
                    <tr>
                      <td>Não</td>

                          {match.bothscore.no.odd ?
                            <td onLoad={RoundOdd(match.bothscore.no.odd)}>{oddRef.current}</td> :
                            <td className="low_chance"> --</td>
                          }
                          {(match.bothscore.no.lastten && match.bothscore.no.lastten != 0) ?
                            <td
                              {...match.bothscore.no.lastten * 100 < 60 ?
                                { className: "low_chance" } :
                                match.bothscore.no.lastten * 100 <= 69 ?
                                  { className: "medium_chance" } :
                                  { className: "high_chance" }}>
                              {(match.bothscore.no.lastten * 100).toFixed(0)}%</td> :
                            <td className="low_chance"> --</td>}
                          {(match.bothscore.no.all && match.bothscore.no.all != 0) ?
                            <td
                              {...match.bothscore.no.all * 100 < 60 ?
                                { className: "low_chance" } :
                                match.bothscore.no.all * 100 <= 69 ?
                                  { className: "medium_chance" } :
                                  { className: "high_chance" }}>
                              {(match.bothscore.no.all * 100).toFixed(0)}%</td> :
                            <td className="low_chance"> --</td>}
                    </tr>
                  </tbody>
                </table> : 
                <h2 className='closedMarket' >Mercado Fechado</h2>
                }
              </Collapse>}
          </Card>
        </Grid>
      }
      {title === 'Golsft' &&
        <Grid>
          <Card className='subcard'>
            <button onClick={toggle} className="visibility_btn">{isOpen ?
              <VisibilityIcon style={{ width: "15px", height: "15px" }} /> :
              <VisibilityOffIcon style={{ width: "15px", height: "15px" }} />}
            </button>
            <div>
              <h4 className="subcard_title">Gols Partida</h4>
            </div>
            <Collapse isOpen={match.golsft ? isOpen : !isOpen}>
              {match.golsft != null ?
              <table>
                <tbody>
                  <tr className='table_header'>
                    <th aria-hidden="true"> </th>
                    <th>Odd</th>
                    <th>Últimos 10 </th>
                    <th>Todos</th>
                  </tr>
                  <tr>
                    {match.golsft ? 
                    <td>Over {match.golsft.handcap}</td> :
                    <td>Over</td>
                    }                  

                    {match.golsft.over.odd ? 
                    <td onLoad={RoundOdd(match.golsft.over.odd)}>{oddRef.current}</td>:
                    <td className="low_chance"> --</td>
                    }
                    {(match.golsft.over.lastten && match.golsft.over.lastten != 0) ? 
                    <td
                    {...match.golsft.over.lastten * 100 < 60 ?
                      { className: "low_chance" } :
                      match.golsft.over.lastten * 100 <= 69?
                        { className: "medium_chance" } :
                        { className: "high_chance" }}>
                    {(match.golsft.over.lastten * 100).toFixed(0)}%</td>:
                    <td className="low_chance"> --</td>}
                    {(match.golsft.over.all && match.golsft.over.all != 0) ?
                    <td
                    {...match.golsft.over.all * 100 < 60?
                      { className: "low_chance" } :
                      match.golsft.over.all * 100 <= 69 ?
                        { className: "medium_chance" } :
                        { className: "high_chance" }}>
                    {(match.golsft.over.all * 100).toFixed(0)}%</td>:
                    <td className="low_chance"> --</td>}
                  </tr>
                  <tr>
                  {match.golsft ? 
                  <td>Under {match.golsft.handcap}</td> :
                  <td>Under</td>}  

                    {match.golsft.under.odd ? 
                    <td onLoad={RoundOdd(match.golsft.under.odd)}>{oddRef.current}</td>:
                    <td className="low_chance"> --</td>
                    }
                    {(match.golsft.under.lastten && match.golsft.under.lastten != 0) ? 
                    <td
                    {...match.golsft.under.lastten * 100 < 60 ?
                      { className: "low_chance" } :
                      match.golsft.under.lastten * 100 <= 69 ?
                        { className: "medium_chance" } :
                        { className: "high_chance" }}>
                    {(match.golsft.under.lastten * 100).toFixed(0)}%</td>:
                    <td className="low_chance"> --</td>}
                      {(match.golsft.under.all && match.golsft.under.all != 0) ?
                    <td
                    {...match.golsft.under.all * 100 < 69 ?
                      { className: "low_chance" } :
                      match.golsft.under.all  * 100 <= 69 ?
                        { className: "medium_chance" } :
                        { className: "high_chance" }}>
                    {(match.golsft.under.all * 100).toFixed(0)}%</td>:
                    <td className="low_chance"> --</td>}
                  </tr>
                </tbody>
              </table>:
              <h2 className='closedMarket' >Mercado Fechado</h2> }

            </Collapse>
          </Card>
        </Grid>
      }
      {title === 'HomeGols' &&
        <Grid>
          <Card className='subcard'>
            <button onClick={toggle} className="visibility_btn">{isOpen ?
              <VisibilityIcon style={{ width: "15px", height: "15px" }} /> :
              <VisibilityOffIcon style={{ width: "15px", height: "15px" }} />}
            </button>
            <div>
              {match?.home?.name &&
                <h4 className="subcard_title">Gols {match?.home?.name}</h4>}
            </div>
            {match &&
              <Collapse isOpen={match.homegols ? isOpen : !isOpen}>
                {match.homegols ?
                <table>
                  <tbody>
                    <tr className='table_header'>
                      <th aria-hidden="true"> </th>
                      <th>Odd</th>
                      <th>Últimos 10 </th>
                      <th>Todos</th>
                    </tr>
                    <tr>
                      {match.homegols ?
                        <td>Over {match.homegols.handcap}</td> :
                        <td>Over</td>}

                          {match.homegols.over.odd ?
                            <td onLoad={RoundOdd(match.homegols.over.odd)}>{oddRef.current}</td> :
                            <td className="low_chance"> --</td>
                          }
                          {(match.homegols.over.lastten && match.homegols.over.lastten != 0) ?
                            <td
                              {...match.homegols.over.lastten * 100< 60 ?
                                { className: "low_chance" } :
                                match.homegols.over.lastten * 100 <= 69 ?
                                  { className: "medium_chance" } :
                                  { className: "high_chance" }}>
                              {(match.homegols.over.lastten * 100).toFixed(0)}%</td> :
                            <td className="low_chance"> --</td>}
                          {(match.homegols.over.all && match.homegols.over.all != 0) ?
                            <td
                              {...match.homegols.over.all * 100 < 60 ?
                                { className: "low_chance" } :
                                match.homegols.over.all * 100 <= 69 ?
                                  { className: "medium_chance" } :
                                  { className: "high_chance" }}>
                              {(match.homegols.over.all * 100).toFixed(0)}%</td> :
                            <td className="low_chance"> --</td>}
                    </tr>
                    <tr>
                      {match.homegols ?
                        <td>Under {match.homegols.handcap}</td> :
                        <td>Under</td>}
                          {(match.homegols.under.odd && match.homegols.under.odd != 0 ) ?
                            <td onLoad={RoundOdd(match.homegols.under.odd)}>{oddRef.current}</td> :
                            <td className="low_chance"> --</td>}
                          {(match.homegols.under.lastten && match.homegols.under.lastten != 0) ?
                            <td
                              {...match.homegols.under.lastten * 100 < 60 ?
                                { className: "low_chance" } :
                                match.homegols.under.lastten * 100 <= 69 ?
                                  { className: "medium_chance" } :
                                  { className: "high_chance" }}>
                              {(match.homegols.under.lastten * 100).toFixed(0)}%</td> :
                            <td className="low_chance"> --</td>}
                          {(match.homegols.under.all && match.homegols.under.all != 0) ?
                            <td
                              {...match.homegols.under.all * 100 < 60 ?
                                { className: "low_chance" } :
                                match.homegols.under.all * 100 <= 69 ?
                                  { className: "medium_chance" } :
                                  { className: "high_chance" }}>
                              {(match.homegols.under.all * 100).toFixed(0)}%</td> :
                            <td className="low_chance"> --</td>}
                    </tr>
                  </tbody>
                </table> : 
                 <h2 className='closedMarket' >Mercado Fechado</h2>}
              </Collapse>}
          </Card>
        </Grid>
      }
      {title === 'AwayGols' &&
        <Grid>
          <Card className='subcard'>
            <button onClick={toggle} className="visibility_btn">{isOpen ?
              <VisibilityIcon style={{ width: "15px", height: "15px" }} /> :
              <VisibilityOffIcon style={{ width: "15px", height: "15px" }} />}
            </button>
            <div>
              {match?.away?.name &&
                <h4 className="subcard_title">Gols {match?.away?.name}</h4>}
            </div>
            {match &&
              <Collapse isOpen={match.awaygols ? isOpen : !isOpen}>
                {match.awaygols ?
                <table>
                  <tbody>
                    <tr className='table_header'>
                      <th aria-hidden="true"> </th>
                      <th>Odd</th>
                      <th>Últimos 10 </th>
                      <th>Todos</th>
                    </tr>
                    <tr>
                      {match.awaygols ?
                        <td>Over {match.awaygols.handcap}</td> :
                        <td>Over</td>}
                          {match.awaygols.over.odd ?
                            <td onLoad={RoundOdd(match.awaygols.over.odd)}>{oddRef.current}</td> :
                            <td className="low_chance"> --</td>}
                          {(match.awaygols.over.lastten && match.awaygols.over.lastten != 0) ?
                            <td
                              {...match.awaygols.over.lastten * 100 < 60 ?
                                { className: "low_chance" } :
                                match.awaygols.over.lastten * 100 <= 69 ?
                                  { className: "medium_chance" } :
                                  { className: "high_chance" }}>
                              {(match.awaygols.over.lastten * 100).toFixed(0)}%</td> :
                            <td className="low_chance"> --</td>}
                          {(match.awaygols.over.all && match.awaygols.over.all != 0) ?
                            <td
                              {...match.awaygols.over.all *  100 < 60 ?
                                { className: "low_chance" } :
                                match.awaygols.over.all * 100 <= 69 ?
                                  { className: "medium_chance" } :
                                  { className: "high_chance" }}>
                              {(match.awaygols.over.all * 100).toFixed(0)}%</td> :
                            <td className="low_chance"> --</td>}
                    </tr>
                    <tr>
                      {match.awaygols ?
                        <td>Under {match.awaygols.handcap}</td> :
                        <td>Under</td>}
                          {match.awaygols.under.odd ?
                            <td onLoad={RoundOdd(match.awaygols.under.odd)}>{oddRef.current}</td> :
                            <td className="low_chance"> --</td>}
                          {(match.awaygols.under.lastten && match.awaygols.under.lastten != 0) ?
                            <td
                              {...match.awaygols.under.lastten * 100 < 60 ?
                                { className: "low_chance" } :
                                match.awaygols.under.lastten * 100 <= 69 ?
                                  { className: "medium_chance" } :
                                  { className: "high_chance" }}>
                              {(match.awaygols.under.lastten * 100).toFixed(0)}%</td> :
                            <td className="low_chance"> --</td>}
                          {(match.awaygols.under.all && match.awaygols.under.all != 0) ?
                            <td
                              {...match.awaygols.under.all * 100 < 60 ?
                                { className: "low_chance" } :
                                match.awaygols.under.all * 100 <=  69 ?
                                  { className: "medium_chance" } :
                                  { className: "high_chance" }}>
                              {(match.awaygols.under.all * 100).toFixed(0)}%</td> :
                            <td className="low_chance"> --</td>}
                    </tr>
                  </tbody>
                </table> : 
                 <h2 className='closedMarket' >Mercado Fechado</h2> }
              </Collapse>}
          </Card>
        </Grid>
      }
    </>
  )
}

export default Subcard