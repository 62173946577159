import React, { useState, useRef, useEffect } from 'react'
import DashboardCard from '../DashboardCard';
import styled from 'styled-components'
import { SizeMe } from 'react-sizeme'

import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap
} from "react-grid-drag";
import useGridDragDropState, { SELECTED } from './useGridDragDropState';
import { FormControlLabel, Paper, Switch, useMediaQuery } from '@material-ui/core';
import { DEFAULT, FIFA22, _10_minutes_league, _8_minutes_league, _12_minutes_league } from '../httpRequests/getMatches';
import { ButtonGroup } from 'reactstrap';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import LogoFifa from '../../../../assets/FIFA_logo.png'


const StyledGridItem = styled(GridItem)`
padding:1rem;
cursor: move; /* fallback if grab cursor is unsupported */
cursor: grab;
.css-ny5bw0-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track{
  background-color: #00ff00;
}
&:active{
  user-select: none;
  cursor: grabbing;
}
.card-body {
  height: fit-content;
}
@media screen 
and (max-width: 575px) {
  padding:1rem 0;
}
`
const StyledSwitchField = styled.div`
background: linear-gradient(0deg, #000000 0%, ${({ gradientColor }) => gradientColor} 99%);
padding: 0 5px 1rem 1rem;
position: absolute;
z-index: 1;
top: 4px;
right: 4px;
border-radius: 0 0.5rem 0 0.5rem; 
display: flex;
gap: 1rem;
flex-direction: row;
width: 8vw;
gap: 1rem;
@media screen 
and (max-width: 470px) {
  width: 16vw;
}
@media screen and (min-width: 470px) 
and (max-width: 650px) {
  width: 25vw;
}
@media screen 
and (min-width: 650px)
and (max-width: 999px) {
  width: 10vw;
}
@media screen and (min-width: 1000px)
and (max-width: 1350px)  {
  width: 6vw;
}
@media screen and (min-width: 1351px)
and (max-width: 1750px)  {
  width: 6vw;
}
.MuiTypography-body1{
  color: white;
  font-weight: bold;
}
.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track{
  background-color: #00ff00;
}
`
const StyledButtons = styled(ButtonGroup)`
color: white;
position: absolute;
transform: scale(0.8);
top: 0;
left: calc(50% - 3rem);
width: 6rem;
.arrow{
  padding: 1rem;
  margin: auto;
}
`
export const switchField = (switchProps = {}) => (
  <StyledSwitchField gradientColor={switchProps.gradientColor}>
    {/* <Switch {...switchProps} /> */}
    <img src={LogoFifa} alt="Logo FIFA 22" className="FifaLogo" />
  </StyledSwitchField>)

const upDownButtons = (idx, upOrDownItem, items) => {
  const style = { backgroundColor: "red" }
  // console.log(items)
  return <StyledButtons >
    {!(idx === 0) &&
      <div className="arrow" onClick={() => upOrDownItem().up(idx)}>
        <ArrowUpward />
      </div>}
    {!(idx >= items.length - 1) &&
      <div className="arrow" onClick={() => upOrDownItem().down(idx)}>
        <ArrowDownward />
      </div>}
  </StyledButtons>

}
const liveCard = (match, toggleSelectedItem, idx, upOrDownItem, { boxesPerRow }, items) => {


  const selectedOdd = match[SELECTED]
  const checked = selectedOdd === FIFA22

  const switchProps = {
     checked,
     onChange : ()=> toggleSelectedItem(match.id),
     style:{color: checked ? '#00ff00' : ""},
     gradientColor: match.league === _10_minutes_league ? "#c2019b" :  match.league ===  _8_minutes_league ? '#00d4ff ' 
     : match.league ===  _12_minutes_league ? "#015d15" : "#d91fbb"
  }

  return <div style={{position: 'relative'}}>
    {/* {console.log(match[selectedOdd])} */}

      <DashboardCard prop={match}/> 

    {boxesPerRow === 1 && upDownButtons(idx, upOrDownItem, items)}
    {match &&
      switchField(switchProps)}
  </div>
}


const getLargestHeight = (heightsArray) => {
  return heightsArray
    .reduce((total, currentValue) => {
      if (total > currentValue) return total;
      return currentValue
    }, 0)
}
const updateRowHeight = ({ itemsHeight, setRowHeight }) => {
  const heightsArray =
    //Object to Array
    Object.values(itemsHeight.current)
      //Filter only Valid values
      .filter(height => height)
  if (heightsArray.length > 0) {
    const largestHeight = getLargestHeight(heightsArray)
    setRowHeight(largestHeight)
  }
}
const DragAndDrop = ({ updateGridRef }) => {

  const NewGrid = () => {
    const { items, onChange, toggleSelectedItem, upOrDownItem } = useGridDragDropState(updateGridRef)

    const [rowHeight, setRowHeight] = useState(800)
    const itemsHeight = useRef({})
    const setItemsHeight = (height, id) => {
      itemsHeight.current = {
        ...itemsHeight.current,
        [id]: height
      }
    }

    useEffect(() => {
      const interval = setInterval(() => {
        updateRowHeight({ itemsHeight, setRowHeight })
      }, 800);
      return () => clearInterval(interval);
  }, [items]);

    const _3rows = useMediaQuery('(min-width:1200px)');
    const _2rows = useMediaQuery('(min-width:993px)');

    const GridDropZoneProps = () => {
      const boxesPerRow = () => {
        const columns =
          _3rows ? 3 :
            _2rows ? 2 :
              1

        if (items.length <= 3 && items.length <= columns) { return items.length }
        return columns;
      }

      const totalRows = Math.ceil(items.length / boxesPerRow())
      const paddingBottom = 100
      const containerHeight = (totalRows * rowHeight) + paddingBottom

      return {
        id: "items",
        boxesPerRow: boxesPerRow(),
        rowHeight,
        style: { height: `${containerHeight}px` }
      }
    }
    const cardItems = items.map((match, idx) =>
      <div className="gridItem" key={match.id} style={{paddingBottom: "10px"}}>
        <SizeMe
          monitorHeight>
          {({ size }) => {
            setItemsHeight(size.height, match.id)
            return liveCard(match, toggleSelectedItem, idx, upOrDownItem, GridDropZoneProps(), items)
          }}
        </SizeMe>
      </div>
    )

    return <>

    {window.innerWidth <= 500 ? 
    <div >
      {cardItems}
    </div> :
      <GridContextProvider onChange={onChange}>
        <GridDropZone {...GridDropZoneProps()}>
          {items.map((match, idx) =>
            <StyledGridItem className="gridItem" key={match.id}>
              <SizeMe
                monitorHeight>
                {({ size }) => {
                  setItemsHeight(size.height, match.id)
                  return liveCard(match, toggleSelectedItem, idx, upOrDownItem, GridDropZoneProps(), items)
                }}
              </SizeMe>
            </StyledGridItem>
          )}
        </GridDropZone>
      </GridContextProvider>}

    </>
  }
  return <><NewGrid /></>
}

export default DragAndDrop