import {Route} from 'react-router-dom'
import Layout from '../../Layout'
import Pages from './Pages'

const WrappedRoutes = () => {
    return (
        <div>
        <Layout />
        <div className="container__wrap">
          <Route path="/pages" component={Pages} />
        </div>
      </div>
    )
}

export default WrappedRoutes
