import { useState, useRef } from 'react'
import { ModalBody, ModalHeader, Card } from 'reactstrap';
import { Grid } from '@material-ui/core';
import betLogo from '../../../assets/bet-365-ico.png'

const Dutching = ({ match, home, away }) => {
  // console.log(home)
  const [profit1, setProfit] = useState(0)
  const [profit2, setProfit2] = useState(0)
  const [profit3, setProfit3] = useState(0)
  const [profit4, setProfit4] = useState(0)
  const [totalProfit, setTotalProfit] = useState(0)
  const [totalProfit2, setTotalProfit2] = useState(0)
  const oddRef = useRef(0)

  const RoundOdd = (data) => {
    const value = Number(data)
    const rounded = value.toFixed(1)
    oddRef.current = rounded
  }


  const calc = (e) => {
    const stake = (e.target.value)
    // Dutching Próximo Gol
    if (!match.nextgol || (!match.nextgol.home || !match.nextgol.away)) {
      setProfit(0)
      setProfit2(0)
      setTotalProfit(0)
    } else {
      const oddP1 = match.nextgol.home
      const oddP2 = match.nextgol.away
      const p1 = 1 / oddP1
      const p2 = 1 / oddP2
      const sumP = p1 + p2
      const result1 = ((p1 / sumP) * stake).toFixed(2)
      const result2 = ((p2 / sumP) * stake).toFixed(2)
      const totalProfit = ((result1 * oddP1) - stake).toFixed(2)
      setProfit(result1)
      setProfit2(result2)
      setTotalProfit(totalProfit)
    }
    // Dutching Resultado Final
    if (!match.winnerft) {
      setProfit3(0)
      setProfit4(0)
      setTotalProfit2(0)
    } else {
      const finalOddP1 = match.winnerft.home.odd
      const finalOddP2 = match.winnerft.away.odd
      const finalP1 = 1 / finalOddP1
      const finalP2 = 1 / finalOddP2
      const finalSumP = finalP1 + finalP2
      const finalTotal = ((finalP1 / finalSumP) * stake).toFixed(2)
      const finalTotal2 = ((finalP2 / finalSumP) * stake).toFixed(2)
      const totalProfit2 = ((finalTotal * finalOddP1) - stake).toFixed(2)
      setProfit3(finalTotal)
      setProfit4(finalTotal2)
      setTotalProfit2(totalProfit2)
    }
  }
  return (
    <>
      <form className="form-dutching">
        <ModalHeader className="calcheader">
          <button className="bet_dutching">
            <a target='_blank' href={`https://www.bet365.com/#/IP/EV${match.bet365id}`}>
              <img src={betLogo} alt="Logo Bet365" />
            </a>
          </button>
          <div className="dutch-title">Calculadora de Dutching </div>
          <lable>
            <h2>Informe a <span>stake</span> </h2>
            <input type="number" placeholder="$ Valor" onChange={calc} />
          </lable>
        </ModalHeader>
        <ModalBody className="dutching">
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Card className='subcard dutching-card'>
                <div>
                  <h4 className="subcard_title">Proximo Gol</h4>
                </div>

                <table>
                  <tbody>
                    <tr className='table_header'>
                      <th></th>
                      <th>Odd</th>
                      <th>Stake</th>
                    </tr>
                    {!match.nextgol ?
                    <>
                      <tr>
                      <td className="low_chance"> --</td>
                      <td className="low_chance"> --</td>
                      <td className="low_chance"> --</td>
                      </tr>
                      <tr>
                      <td className="low_chance"> --</td>
                      <td className="low_chance"> --</td>
                      <td className="low_chance"> --</td>
                      </tr>
                      </> :
                      <>
                    <tr>
                      <td>{home.name}</td>

                      {match.nextgol.home ?
                        <td onLoad={RoundOdd(match.nextgol.home)}
                          {...match.nextgol.home + match.nextgol.away <= 4 ?
                            { className: "low_chance" } :
                            match.nextgol.home + match.nextgol.away >= 7 ?
                              { className: "high_chance" } :
                              { className: "medium_chance" }}>
                          {oddRef.current}</td> :
                        <td className="low_chance"> --</td>}
                      {!match.nextgol.home ?
                        <td className="low_chance"> --</td> :
                        <td>$ {profit1}</td>}
                    </tr>
                    <tr>
                      <td >{away.name}</td>
                      {match.nextgol.away ?
                        <td onLoad={RoundOdd(match.nextgol.away)}
                          {...match.nextgol.home + match.nextgol.away <= 4 ?
                            { className: "low_chance" } :
                            match.nextgol.home + match.nextgol.away >= 7 ?
                              { className: "high_chance" } :
                              { className: "medium_chance" }}>
                          {oddRef.current}</td> :
                        <>
                          <td className="low_chance"> --</td>
                        </>}
                      <td>$ {profit2}</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Lucro</td>
                      {(!match.nextgol.home || !match.nextgol.away) ?
                        <td className="low_chance"> --</td> :
                        <td>$ {totalProfit}</td>}
                    </tr>
                    </>}
                  </tbody>
                </table>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card className='subcard dutching-card'>
                <div>
                  <h4 className="subcard_title">Resultado Final</h4>
                </div>
                <table>
                  <tbody>
                    <tr className='table_header'>
                      <th></th>
                      <th>Odd</th>
                      <th>Geral</th>
                      <th>Stake</th>
                    </tr>
                    <tr>
                      <td>{match.home.name}</td>
                      {match.winnerft ?
                        <>
                          {match.winnerft.home.odd &&
                            <td onLoad={RoundOdd(match.winnerft.home.odd)}
                              {...match.winnerft.home.odd + match.winnerft.away.odd <= 4 ?
                                { className: "low_chance" } :
                                match.winnerft.home.odd + match.winnerft.away.odd >= 7 ?
                                  { className: "high_chance" } :
                                  { className: "medium_chance" }}>
                              {oddRef.current}</td>}
                          {match.winnerft.home.all &&
                            <td
                              {...match.winnerft.home.all * 100 <= 59 ?
                                { className: "low_chance" } :
                                match.winnerft.home.all * 100 < 69 ?
                                  { className: "medium_chance" } :
                                  { className: "high_chance" }}>
                              {(match.winnerft.home.all * 100).toFixed(0)}%</td>}
                        </> :
                        <>
                          <td className="low_chance"> --</td>
                          <td className="low_chance"> --</td>
                          <td className="low_chance"> --</td>
                        </>}
                      <td>$ {profit3} </td>
                    </tr>
                    <tr>
                      {match.away.name &&
                        <td >{match.away.name}</td>}
                      {match.winnerft ?
                        <>
                          <td onLoad={RoundOdd(match.winnerft.away.odd)}
                            {...match.winnerft.home.odd + match.winnerft.away.odd <= 4 ?
                              { className: "low_chance" } :
                              match.winnerft.home.odd + match.winnerft.away.odd >= 7 ?
                                { className: "high_chance" } :
                                { className: "medium_chance" }}>
                            {oddRef.current}</td>
                          <td
                            {...match.winnerft.away.all * 100 <= 59 ?
                              { className: "low_chance" } :
                              match.winnerft.away.all * 100 < 69 ?
                                { className: "medium_chance" } :
                                { className: "high_chance" }}>
                            {(match.winnerft.away.all * 100).toFixed(0)}%</td>
                        </> :
                        <>
                          <td className="low_chance"> --</td>
                          <td className="low_chance"> --</td>
                          <td className="low_chance"> --</td>
                        </>}
                      <td>$ {profit4}</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Lucro</td>
                      <td>$ {totalProfit2}</td>
                    </tr>
                  </tbody>
                </table>
              </Card>
            </Grid>
          </Grid>
        </ModalBody>
      </form>
    </>
  )
}

export default Dutching
