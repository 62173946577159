import { useEffect, useState, useRef } from 'react';
import { Col, Container, Row, Modal, ModalBody, ModalHeader } from 'reactstrap';
import Logo from '../../assets/Logo_beta.png'
// import Logo from '../../assets/JT-manager-logo.png'
import { Redirect } from 'react-router-dom';
import LeagueFilter from './LeagueFilter';
import { _10_minutes_league, _12_minutes_league, _8_minutes_league, _12_minutes_gt_league } from './components/httpRequests/getMatches'
import DragAndDrop from './components/DragAndDrop/DragAndDrop';
import NoMatchs from './components/NoMatchs';
import ErrorMsg from './components/ErrorMsg';
import getMatches from './components/httpRequests/getMatches';



const Dashboard = () => {
    const [modalActive, setModalActive] = useState(false)
    const Fifa8 = useRef(false)
    const Fifa10 = useRef(false)
    const Fifa12 = useRef(false)
    const Fifa12GT = useRef(false)

    const updateGrid = useRef()
    const dragAndDropProps = { updateGridRef: updateGrid }
    const Wrapper = () => {
        const initState = {
            load: true,
            data: [],
            error: '',
        };
        const [matchs, setMatchs] = useState(initState);
        console.log(matchs.data, 'matchs.data')

        const Fifa8Data = matchs.data !=  null ? matchs.data.filter(match => match.league === _8_minutes_league) : []
        const Fifa10Data = matchs.data !=  null ? matchs.data.filter(match => match.league === _10_minutes_league) : []
        const Fifa12Data = matchs.data !=  null ? matchs.data.filter(match => match.league === _12_minutes_league) : []
        const Fifa12GTData = matchs.data !=  null ? matchs.data.filter(match => match.league === _12_minutes_gt_league) : []

        // League filter Logic
        useEffect(() => {
            const fifaList = [
                { fifaRef: Fifa8, dataRef: Fifa8Data },
                { fifaRef: Fifa10, dataRef: Fifa10Data },
                { fifaRef: Fifa12GT, dataRef: Fifa12GTData },
                { fifaRef: Fifa12, dataRef: Fifa12Data }
                
            ]
            const toBeShown = []
            
            fifaList.forEach((item) => {
                if(item.fifaRef.current) {
                    toBeShown.push(...item.dataRef)
                }
            })

            if(toBeShown.length !== 0){
                updateGrid.current(toBeShown)
            } else {
                updateGrid.current(matchs.data)
            }
            // updateGrid.current(toBeShown)
            // console.log('FifaList', fifaList)
            // console.log('ToBeShown', toBeShown)
        })

        const showError = (matchs.data ===  null || matchs.data.length === 0) && matchs.load === false
        const showNoMatchs = matchs.load 


        const toggleModal = () => {
            setModalActive(!modalActive)
        }
        const toggleFifa8 = (e) => {
            e.preventDefault()
            Fifa8.current = !Fifa8.current
        }
        const toggleFifa10 = (e) => {
            e.preventDefault()
            Fifa10.current = !Fifa10.current
        }
        const toggleFifa12 = (e) => {
            e.preventDefault()
            Fifa12.current = !Fifa12.current
        }
        const toggleFifa12GT = (e) => {
            e.preventDefault()
            Fifa12GT.current = !Fifa12GT.current
        }

        useEffect(() => {
            getMatches(matchs, setMatchs)
            const interval = setInterval(() => {
                getMatches(matchs, setMatchs)
            }, 5000);
            return () => clearInterval(interval);
        }, []);

        return (

            <Container fluid
                className="dashboard">
                {!sessionStorage.getItem('AccessToken') &&
                    <Redirect to="/" />}
                <Row>
                    <Col md={12}>
                        <div className="main-title">
                            <img src={Logo} alt="Jesus Tips Logo" className="logo" />
                        </div>
                        <button onClick={toggleModal} className="filter_btn" >
                            <p>Ligas</p>
                            <Modal isOpen={modalActive} toggle={toggleModal} centered aria-labelledby="contained-modal-title-vcenter">
                                <LeagueFilter 
                                toggleFifa8={toggleFifa8} toggleFifa10={toggleFifa10} toggleFifa12={toggleFifa12}
                                Fifa10={Fifa10} Fifa8={Fifa8} Fifa12={Fifa12} Fifa12GT={Fifa12GT} toggleFifa12GT={toggleFifa12GT} />
                            </Modal>
                        </button>
                    </Col>
                </Row>
                {showNoMatchs && <NoMatchs />}

                {showError && <ErrorMsg />}
            </Container >
        )
    }

    return <>
        <Wrapper />
        <DragAndDrop {...dragAndDropProps} />
    </>


}

export default Dashboard;
