
import { Redirect } from "react-router"

const LoginVal = (props) => {

  const logOut = async () => {
    let logout = props.location.state.logout
    if (logout) {
      localStorage.clear()
    }
  }
  logOut()



  return (
    <Redirect to='/' />
  )

}



export default LoginVal
