import {
  Card,
  CardBody,
} from 'reactstrap';
import { Grid } from '@material-ui/core';
import CardHeader from './CardHeader'
import Subcard from './Subcard';



const DashboardCard = ({ prop }) => {
  const match = prop
  // console.log(match, 'match')

  return (
    <>
    {match &&
    <Card className="card-body">
      <CardHeader match={match} />
      <CardBody>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Subcard prop={match} title="Winner" />
          </Grid>
          <Grid item xs={6}>
            <Subcard prop={match} title="Hipótese" />
          </Grid>
          <Grid item xs={6}>
            <Subcard prop={match} title="Ambas Marcam" />
          </Grid>
          <Grid item xs={6}>
            <Subcard prop={match} title="Golsft" />
          </Grid>
          <Grid item xs={6}>
            <Subcard prop={match} title="HomeGols" />
          </Grid>
          <Grid item xs={6}>
            <Subcard prop={match} title="AwayGols" />
          </Grid>
        </Grid>
      </CardBody>
    </Card>}
    </>
  );
}

export default DashboardCard;
