import { Container } from 'reactstrap';
import Loading from '../../../assets/LOADING2.gif'

const NoMatchs = () => {
  return (
    <Container fluid>
      <div className="NoMatchs">
        <img src={Loading} alt="loading" />
      
      </div>
    </Container>
  )
}

export default NoMatchs
