import { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom';

const NextGames = ({ home, away, time, date, league }) => {
  const [HomeImg, setHomeImg] = useState("")
  const [AwayImg, setAwayImg] = useState("")
  
  const HomeName = useRef("")
  const AwayName = useRef("")
  const homeTeam = useRef("")
  const awayTeam = useRef("")
  const gameTime = useRef('00:00')
  const gameDate = useRef("00/00/0000")

  const formatTime = (value) => {
    let unix_timestamp = value
    const gameDate = new Date(unix_timestamp * 1000);
    const hours = gameDate.getHours();
    const minutes = "0" + gameDate.getMinutes();
    const formattedTime = `${hours}:${minutes.substr(-2)}`;

    gameTime.current = formattedTime
  }

  const formatDate = (value) => {
    var year = value.toString().slice(0, 2)
    var month = value.toString().slice(4, 6)
    var day = value.toString().slice(6)

    if (day >= 10) { var day = '0'+day+''; }
    if (month >= 10) { var month = '0'+month+''; }

    //gameDate.current = `${day}/${month}/${year}`
    gameDate.current = value
    return gameDate.current
  }

  const formatHome = (home) => {
    const separador = home.split("(")
    const teamAndPlayer = separador.toString().split(")")[0]
    const playerName = teamAndPlayer.toString().split(",")[1]
    const teamName = teamAndPlayer.toString().split(",")[0]
    HomeName.current = playerName
    homeTeam.current = teamName
  }

  const formatAway = (value) => {
    const separador = value.split("(")
    const teamAndPlayer = separador.toString().split(")")[0]
    const playerName = teamAndPlayer.toString().split(",")[1]
    const teamName = teamAndPlayer.toString().split(",")[0]
    AwayName.current = playerName
    awayTeam.current = teamName
  }


  useEffect(() => {
    try {
      const homeSrc = require(`../../assets/players_imgs/${HomeName.current.toLowerCase()}.png`)
      setHomeImg(homeSrc)
    }
    catch (error) {
      setHomeImg(require("../../assets/players_imgs/placeholder.png"))
    }
    try {
      const awaySrc = require(`../../assets/players_imgs/${AwayName.current.toLowerCase()}.png`)
      setAwayImg(awaySrc)
    }
    catch (error) {
      setAwayImg(require("../../assets/players_imgs/placeholder.png"))
    }
  }, [formatHome, formatAway])

  return (
    <li >
      <span className="game-date" >{formatDate(date)}</span>
      <span onLoad={formatTime(time)}>{gameTime.current}</span>
      <div className="nextgames-imgs">
        {HomeImg.default &&
          <img src={HomeImg.default} alt={`Foto do jogador`} />}
      </div>
      <Link to={{
                pathname: '/pages/headtoheadgame',
                state: {
                  home: HomeName.current,
                  away: AwayName.current,
                  league: league
                }
              }} className="nextGames-link">
      <span onChange={formatHome(home)}>{`${homeTeam.current} (${HomeName.current})`}</span>
      <span style={{ color: "#FFF600" }}>Vs</span>
      <span onChange={formatAway(away)}>{`${awayTeam.current} (${AwayName.current})`}</span>
      </Link>
      <div className="nextgames-imgs">
        {AwayImg.default &&
          <img src={AwayImg.default} alt={`Foto do jogador`} />}
      </div>
    </li>
  )
}

export default NextGames
