import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import '../../scss/app.scss';
import Router from './Router/Router'


const App = () => {

  return (
    <BrowserRouter>
        <Router />
    </BrowserRouter>
  );
};

export default App;
