import { Link } from 'react-router-dom';
import FacebookIcon from 'mdi-react/FacebookIcon';
import GooglePlusIcon from 'mdi-react/GooglePlusIcon';
import LogInForm from './components/LogInForm';
import { Col, Container, Row } from 'reactstrap';
// import Logo from '../../assets/JT-manager-logo.png'
import Logo from '../../assets/Logo_beta.png'

const LogIn = () => {

  return (
    <>

      <div className="account">
        <div className="account__wrapper">

          <Col md={12}>
            <div className="main-title" style={{ marginBottom: "90px" }}>
              <img src={Logo} alt="Jesus Tips Logo" className="logo" />
            </div>
          </Col>
          <div className="account__card">
            <div className="account__head">
              <h3 className="account__title" style={{ color: "#FFF" }} >Bem Vindo ao <span style={{ color: "#FFF600" }}>Jesus Tips Manager </span></h3>
              {/* <h4 className="account__subhead subhead">Consultoria esportiva</h4> */}
            </div>

            <LogInForm />
            {/* <div className="account__or">
          <p>Ou facilmente utilizando</p>
        </div>
        <div className="account__social">
          <Link
            className="account__social-btn account__social-btn--facebook"
            to="/pages/live"
          >
            <FacebookIcon />
          </Link>
          <Link
            className="account__social-btn account__social-btn--google"
            to="/pages/live"
          >
            <GooglePlusIcon />
          </Link>
        </div> */}
          </div>
        </div>
      </div>
    </>
  )
};

export default LogIn;

// if you want to add select, date-picker and time-picker in your app you need to uncomment the first
// four lines in /scss/components/form.scss to add styles
