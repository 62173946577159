import axios from "axios";
import axiosRetry from "axios-retry";

//Used constant strings
export const _10_minutes_league = "Esoccer Live Arena - 10 mins play"
export const _12_minutes_league = "Esoccer Liga Pro - 12 mins play"
export const _12_minutes_gt_league = "Esoccer Liga GT - 12 mins play"
export const _8_minutes_league = "Esoccer Battle - 8 mins play"
export const DEFAULT = "default"
export const FIFA22 = "fifa22"
export const NO_GAMES_INPLAY = "No games inplay"
export const productionRoute = "https://api.jesustipsmanager.com/api/v2/"
export const devRoute = "https://jesustips-matrix.ngrok.io/api/"
export const jsonRoute = "http://localhost:3001/matchs"


const getMatches = async (matchs,setMatchs) => {
    try {
        axios.defaults.headers.get['Content-Type'] = 'application/json;charset=utf-8';
        axios.defaults.headers.get['Access-Control-Allow-Origin'] = '*';
        axiosRetry(axios, {
            retries: 3, // number of retries
            retryDelay: (retryCount) => {
                console.log(`retry attempt: ${retryCount}`);
                return retryCount * 500; // time interval between retries
            },
            retryCondition: (error) => {
                // if retry condition is not specified, by default idempotent requests are retried
                return error.response.status === 500;
            },
        });
        const res = {
            odds: await axios.get(`${productionRoute}odd`),
            // odds_fifa22: await axios.get(`${productionRoute}odd/fifa22`)
        }
        const treatedRes = res.odds.data
        // const treatedRes = 
        // res.odds.data === NO_GAMES_INPLAY? 
        // NO_GAMES_INPLAY: 
        // mergeOdds(res)
        // console.log(treatedRes)
        setMatchs({ ...matchs, load: false, data: treatedRes });

    } catch (error) {
        // debugger
        setMatchs({ ...matchs, load: false, error });
    }

}

export default getMatches
