import { useState, useEffect } from 'react'
import axios from 'axios';
import { Link } from 'react-router-dom';
import {
  Col,
  Container,
  Row,
  Modal,
  Card,
  CardBody,
  CardTitle,
} from 'reactstrap';
// import Logo from '../../assets/JT-manager-logo.png'
import Logo from '../../assets/Logo_beta.png'
import Dutching from '../Dashboard/components/Dutching';
import betLogo from '../../assets/bet-365-ico.png'
import betanoLogo from '../../assets/betano-logo.png'
import General from './components/General';
import ToHead from './components/ToHead';
import Lastfive from './components/Lastfive';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import OverUnder from './components/OverUnder';
import OverUnderHT from './components/OverUnderHT';
import { Redirect } from 'react-router';
import { switchField } from '../Dashboard/components/DragAndDrop/DragAndDrop';
import { _10_minutes_league, _12_minutes_league, _8_minutes_league } from '../Dashboard/components/httpRequests/getMatches';
import League8Logo from '../../assets/esports-battle-8.png'
import League10Logo from '../../assets/CLA.png'
import League12Logo from '../../assets/Logo Fifa 12.png'
import LeagueGT12Logo from '../../assets/GT-logo.png'
import { devRoute } from '../Dashboard/components/httpRequests/getMatches';
import { productionRoute } from '../Dashboard/components/httpRequests/getMatches';
import NoMatchs from '../Dashboard/components/NoMatchs';

const components = [
  {
    'name': General,
    'id': 1
  },
  {
    'name': Lastfive,
    'id': 2
  },
  {
    'name': ToHead,
    'id': 3
  },
  {
    'name': OverUnderHT,
    'id': 4
  },
  {
    'name': OverUnder,
    'id': 5
  }
]

const HeadToHead = (props) => {
  // debugger
  const [selectedFifa22, setSelectedFifa22] = useState(true)
  const apiRoute = `${productionRoute}headtohead`


  const [modal, setModal] = useState(false)
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [order, setOrder] = useState(components)
  const toggleModal = () => setModal(!modal);
  // console.log(props.location.state )
  const match = props.location.state.match
  const league = props.location.state.league
  const homeProp = props.location.state.home
  const awayProp = props.location.state.away


  function handleOnDragEnd(result) {
    if (!result.destination) return
    if (result.destination.index !== result.source.index) {
      const items = Array.from(order)
      const [reorderedItems] = items.splice(result.source.index, 1)
      items.splice(result.destination.index, 0, reorderedItems)
      setOrder(items)
    } else {
      const items = Array.from(order)
      setOrder(items)
    }
  }

  const getHeadData = async () => {
    axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
    axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

    if (match) {
      const home = match.home.name.toLowerCase()
      const away = match.away.name.toLowerCase()
      let payload = { "home": home, "away": away }
      // console.log(payload, 'playload')
      let res = await axios.post(apiRoute, payload, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        },
        responseType: "json",
      })
      let PvPdata = res.data
      setData(PvPdata)
      setLoading(false)
    } else {
      const home = homeProp.toLowerCase()
      const away = awayProp.toLowerCase()
      let payload = { "home": home, "away": away }
      // console.log(payload, 'playload')
      let res = await axios.post(apiRoute, payload, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        },
        responseType: "json",
      })
      let PvPdata = res.data
      // console.log(PvPdata, 'data')
      setData(PvPdata)
      setLoading(false)
    }
  }
  
  useEffect(() => {
    getHeadData()
  }, [selectedFifa22])

  const showSwitch = league
  const switchProps = league ? {
    checked: selectedFifa22,
    onChange : ()=> setSelectedFifa22(prev => !prev),
    style:{color: selectedFifa22 ? '#00ff00' : ""},
    gradientColor: league === _10_minutes_league ? "#c2019b" :  league ===  _8_minutes_league ? '#00d4ff ' : league ===  _12_minutes_league ? "#015d15" : "#d91fbb"
 } : ""

  return (
    <>
      <Container fluid>
        <Row>
          {!sessionStorage.getItem('AccessToken') &&
            <Redirect to="/" />}
          <Col md={12} >
            <Link to="/pages/live">
              <div className="main-title">
                <img src={Logo} alt="Jesus Tips Logo" className="logo" />
              </div>
            </Link>
          </Col>
        </Row>
      </Container>
      {loading === true ? 
      <NoMatchs/> :
      <Col md={12} className="headtohead_grid" style={{ position: 'relative' }}>
      {showSwitch && switchField(switchProps)}
      <Card className="card-body">
        <CardTitle className="header__title">
          {match ?
            <div>
            {match.league === _10_minutes_league ?
              <img src={League10Logo} alt="Logo liga 10min" className="logoLiga" /> : 
              match.league === _8_minutes_league ?
              <img src={League8Logo} alt="Logo liga 8min" className="logoLiga" />: 
              match.league === _12_minutes_league ?
              <img src={League12Logo} alt="Logo liga 12min" className="logoLiga"/> :
              <img src={LeagueGT12Logo} alt="Logo liga GT 12min" className="logoLiga"/>}

              <div className="head-title">
                <div className="players-headtohead" > <span className="team">{match.home.team}</span> <h2>{match.home.name}</h2>
                  <h2 className='score'>{match.home.score}</h2>
                  <span className='divisor-headToHead'>-</span>
                  {match.away.score ?
                    <h2 className='score'>{match.away.score}</h2> :
                    <h2 className='score'>0</h2>}
                  <h2>{match.away.name}</h2> <span className="team">{match.away.team}</span>
                </div>
              </div>
            </div> :
            <div>
          {league === "Esoccer Live Arena - 10 mins play" ?
            <img src={League10Logo} alt="Logo liga 10min" className="logoLiga" /> : 
            league === "Esoccer Battle - 8 mins play" ?
            <img src={League8Logo} alt="Logo liga 8min" className="logoLiga" />: 
            <img src={League12Logo} alt="Logo liga 12min" className="logoLiga" />}
            
              <div className="head-title">
                <div className="players-headtohead" ><h2>{homeProp}</h2>
                  <span className='divisor-headToHead'>-</span>
                  <h2>{awayProp}</h2>
                </div>
              </div>
            </div>
          }
        </CardTitle>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <CardBody>
            {order.map((component, id) => {
              return (
                <>
                  <Droppable droppableId={`row${id}`}>
                    {(provider) =>
                      <div ref={provider.innerRef} {...provider.droppableProps}>
                        <Draggable draggableId={`drag${id}`} index={id}>
                          {(provider) =>
                            <div
                              key={id}
                              ref={provider.innerRef}
                              {...provider.draggableProps}
                              {...provider.dragHandleProps}>
                              {match ?
                                <component.name data={data} home={match.home.name} away={match.away.name} key={id} /> :
                                <component.name data={data} home={homeProp} away={awayProp} key={id} />}

                            </div>}
                        </Draggable>
                        {provider.placeholder}
                      </div>}
                  </Droppable>
                </>)
            })}
          </CardBody>
        </DragDropContext>
      </Card>
    </Col>}
    </>
  )
}

export default HeadToHead
