import { useState } from "react";
import BrightnessHighIcon from '@material-ui/icons/BrightnessHigh';
import { BsBrightnessHighFill } from "react-icons/bs";



const MainWrapper = ({ children }) => {
  const [theme, setTheme] = useState(false)
  function handleTheme() {
    setTheme(!theme)
  }

  return (
    <div className={theme ? "theme-light" : "theme-dark"}>
    <div className="wrapper">
    <button className="theme-button" onClick={handleTheme}>
    <BsBrightnessHighFill style={theme ? {color:"#000"} : {color: "#fff"}}/>    
    </button>

    {children}
    </div>
  </div>
  )
}

export default MainWrapper
