import { useState } from 'react'
import { swap } from 'react-grid-drag';
import { DEFAULT, FIFA22, NO_GAMES_INPLAY } from '../httpRequests/getMatches';
import testMatchs from './testMatchs';

export const SELECTED = 'selected';

const setDefaultSelected = items => 
items !=  null ?  items.map(item =>{return {...item}}) : []




const mergeItems = (prevItems, updatedItems) => {
    if (prevItems.length === 0) {
        return setDefaultSelected(updatedItems)
    }
    const updatePrevItems = (item) => {
        // console.log(item, 'item')
        const updatedVersion = updatedItems ? updatedItems.find(({ id }) => id === item.id) : []
        if (updatedVersion) {
            return {...item, ...updatedVersion}
        }
        return { ...item, deleted: true }
    }
    const getNewItems = () =>{
        if(updatedItems){
            return setDefaultSelected(
                updatedItems.filter(item => !prevItems.find(({ id }) => id === item.id))
            )
        }
        return NO_GAMES_INPLAY
    }
    const mergedItems = [...prevItems]
        .map(updatePrevItems)
        .filter(({ deleted }) => !deleted)
        .concat(getNewItems())

    return mergedItems
}

const UP = "UP"
const DOWN = "DOWN"

const useGridDragDropState = (updateGridRef) => {
    const [items, setItems] = useState([]);

    updateGridRef.current = (newItems) => { 
       setItems(prevItems => mergeItems(prevItems, newItems))
    }
    const onChange = (sourceId, sourceIndex, targetIndex, targetId) =>{
        setItems(swap(items, sourceIndex, targetIndex))
    }
    const toggleSelectedItem = (id)=>
        setItems(prevItems=>{
        return prevItems.map(item=>{
            if(item.id !== id)return item
            // switch(item[SELECTED]){
            //     case DEFAULT:
            //         return {...item, [SELECTED]: FIFA22}
            //     case FIFA22:
            //         return {...item, [SELECTED]: DEFAULT}
            // }
        })
    })
    const upOrDownItem = () =>{
        const changeItens = (selectedIdx, upOrDown)=>{
            if(upOrDown === UP && selectedIdx === 0 )return false
            if(upOrDown === DOWN && selectedIdx >= items.length-1  )return  false

            setItems(swap(items, selectedIdx, 
                    upOrDown === UP?
                    selectedIdx-1:
                    selectedIdx +1
            ))
            return true
        }
        return {
            up : (selectedIdx)=>changeItens(selectedIdx, UP),
            down: (selectedIdx)=>changeItens(selectedIdx, DOWN)
        }

     }

    return { items, onChange , toggleSelectedItem, upOrDownItem}
}

export default useGridDragDropState
