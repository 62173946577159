import { useState } from 'react';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { useHistory } from 'react-router-dom';
import { Formik } from "formik";
import * as EmailValidator from "email-validator"; 
import axios from 'axios';

const LogInForm = () => {
  const [loginError, setError] = useState(false)
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const history = useHistory();

  const handleShowPassword = () => {
    setIsPasswordShown(!isPasswordShown);
  };
  return (
    <Formik
      initialValues={{ email: "", password: "" }}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(async () => {
          try {
            let payload = {email: values.email.toLowerCase(), password: values.password}
            axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
            axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
            let res = await axios.post('https://api.jesustipsmanager.com/api/v2/login', payload)
            const token = await res.data
            sessionStorage.setItem('AccessToken', token)
            if (await token) {
              history.push('/pages/live')
            } else {
              logOut()
            }
          } catch (error) {
            setError(!loginError)
          }
          setSubmitting(false);
        }, 500);
      }}
      validate={values => {
        let errors = {};
        if (!values.email) {
          errors.email = "Obrigatório!";
        } else if (!EmailValidator.validate(values.email)) {
          errors.email = "Endereço de Email Inválido";
        }

        const passwordRegex = /(?=.*[0-9])/;
        if (!values.password) {
          errors.password = "Obrigatório";
        } else if (values.password.length < 8) {
          errors.password = "Senha deve 8 ou mais caractéres";
        } else if (!passwordRegex.test(values.password)) {
          errors.password = "Senha inválida. Deve conter um ou mais números";
        }

        return errors;
      }} >
      {props => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit
        } = props;

        return (
          <form onSubmit={handleSubmit} className='form'>
            <div className="form__form-group">
              <label htmlFor="email" className="form__form-group-label" style={{ color: "#fff" }}>Email</label>
              <div className="form__form-group-field">
                <div className="form__form-group-icon">
                <AccountOutlineIcon />
                </div>
                <input
                  id="email"
                  name="email"
                  type="text"
                  placeholder="Email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={errors.email && touched.email && "error"}
                />
                {errors.email && touched.email && (
                  <div style={{ color: "red", position: "absolute", top:"60px"}} >{errors.email}</div>
                )}
              </div>
            </div>
            <div className="form__form-group">
              <label htmlFor="password" className="form__form-group-label" style={{ color: "#fff" }}>Senha</label>
              <div className="form__form-group-field">
                <div className="form__form-group-icon">
                  <KeyVariantIcon />
                </div>
                <input
                  id="password"
                  name="password"
                  type={isPasswordShown ? 'text' : 'password'}
                  placeholder="Senha"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={errors.password && touched.password && "error"}
                />
                <button
                  className={`form__form-group-button${isPasswordShown ? ' active' : ''}`}
                  onClick={() => handleShowPassword()}
                  type="button">
                  <EyeIcon />
                </button>
                {errors.password && touched.password && (
                  <div style={{ color: "red", position: "absolute", top:"60px" }} >{errors.password}</div>
                )}
              </div>
            </div>
            {loginError === true &&
              <div style={{ color: "red" }}>
                Email ou senha incorretos
              </div>
            }
            <button type="submit" disabled={isSubmitting} className="btn login_btn" style={{width:"385px"}}>
              Login
            </button>
            <a href="https://api.jesustipsmanager.com/recuperar-senha/index.php" target="_blank" className="btn login_btn" style={{width:"385px"}}>Recuperar Senha</a>

          </form>
        );
      }}
    </Formik>
  )
};

export default LogInForm;
