import { useState, useRef } from 'react'
import { Col, Container, Row } from 'reactstrap';
// import Logo from '../assets/JT-manager-logo.png'
import Logo from '../assets/Logo_beta.png'
import {Link} from 'react-router-dom'
import { ModalBody, ModalHeader, Card } from 'reactstrap';
import { Grid } from '@material-ui/core';

const DutchingCalc = () => {
  const stake = useRef(0)
  const odd1 = useRef(0)
  const odd2 = useRef(0)
  const odd3 = useRef(0)
  const odd4 = useRef(0)
  const [profit1, setProfit] = useState(0)
  const [profit2, setProfit2] = useState(0)
  const [profit3, setProfit3] = useState(0)
  const [profit4, setProfit4] = useState(0)
  const [totalProfit, setTotalProfit] = useState(0)
  // const profit1 = useRef(0)
  // const profit2 = useRef(0)
  // const profit3 = useRef(0)
  // const profit4 = useRef(0)
  // const totalProfit = useRef(0)


  const calc = (e) => {
    e.preventDefault();
    const bet1 = odd1.current != 0 ? 1 / odd1.current : 0
    const bet2 = odd2.current != 0 ? 1 / odd2.current : 0
    const bet3 = odd3.current != 0 ? 1 / odd3.current : 0
    const bet4 = odd4.current != 0 ? 1 / odd4.current : 0
    const sumBet = bet1 + bet2 + bet3 + bet4
    const result1 = ((bet1 / sumBet) * stake.current).toFixed(2)
    const result2 = ((bet2 / sumBet) * stake.current).toFixed(2)
    const result3 = ((bet3 / sumBet) * stake.current).toFixed(2)
    const result4 = ((bet4 / sumBet) * stake.current).toFixed(2)
    const finalProfit =
      ((result1 * odd1.current) - stake.current).toFixed(2)
    // odd1.current != 0 ? ((result1 * odd1.current) - stake.current).toFixed(2) : 
    // odd2.current != 0 ((result2 * odd2.current) - stake.current).toFixed(2) ? 
    // odd3.current != 0 ((result3 * odd3.current) - stake.current).toFixed(2) :
    // odd4.current != 0 ((result4 * odd4.current) - stake.current).toFixed(2) 
    setProfit(result1)
    setProfit2(result2)
    setProfit3(result3)
    setProfit4(result4)
    setTotalProfit(finalProfit)

  }
  return (
    <Container fluid>
      {!sessionStorage.getItem('AccessToken') &&
        <Redirect to="/" />}
      <Row>
        <Col md={12}>
        <Link to="/pages/live">
            <div className="main-title">
              <img src={Logo} alt="Jesus Tips Logo" className="logo" />
            </div>
        </Link>
        </Col>
      </Row>
      <form className="form-external-dutching">
        <ModalHeader className="calcheader-external">
          <div className="dutch-title">Calculadora de Dutching </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div className="dutching-label" >
              <h2>Informe a <span>stake</span> </h2>
              <input type="number" placeholder="$ Valor" onChange={(e) => stake.current = e.target.value} />
              <button className="calc_btn" onClick={calc}>
                Calcular
              </button>
            </div>
          </div>
        </ModalHeader>
        <ModalBody className="external-dutching">
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Card className='subcard-dutching external-dutching-card'>
                <div>
                  <h4 className="subcard_title">Informe as <span style={{ color: "#FFF600", padding: "0 5px " }}>Odds</span> </h4>
                </div>
                <table>
                  <tbody>
                    <tr>
                      <th />
                      <th>Odd</th>
                      <th>Stake</th>
                    </tr>
                    <tr>
                      <td>Aposta 1</td>
                      <td><input type="number" placeholder="Odd" onChange={(e) => odd1.current = e.target.value} /></td>
                      <td>R$ {profit1}</td>
                    </tr>
                    <tr>
                      <td>Aposta 2</td>
                      <td><input type="number" placeholder="Odd" onChange={(e) => odd2.current = e.target.value} /></td>
                      <td>R$ {profit2}</td>
                    </tr>
                    <tr>
                      <td>Aposta 3</td>
                      <td><input type="number" placeholder="Odd" onChange={(e) => odd3.current = e.target.value} /></td>
                      <td>R$ {profit3}</td>
                    </tr>
                    <tr>
                      <td>Aposta 4</td>
                      <td><input type="number" placeholder="Odd" onChange={(e) => odd4.current = e.target.value} /></td>
                      <td>R$ {profit4}</td>
                    </tr>
                    <tr>
                      <td />
                      <td>Lucro</td>
                      <td>R$ {totalProfit}</td>
                    </tr>
                  </tbody>
                </table>
              </Card>
            </Grid>
          </Grid>
        </ModalBody>
      </form>
    </Container>
  )
}

export default DutchingCalc
