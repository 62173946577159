import {
  Col,
  Container,
  Row
} from 'reactstrap';
// import Logo from '../../assets/JT-manager-logo.png'
import Logo from '../../assets/Logo_beta.png'
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import SearchForm from '../HeadToHead/components/SearchForm'


const Search = () => {

  return (
    <>
      <Container fluid >
        <Row>
          {!sessionStorage.getItem('AccessToken') &&
            <Redirect to="/" />}
          <Col md={12} >
            <Link to="/pages/live">
              <div className="main-title">
                <img src={Logo} alt="Jesus Tips Logo" className="logo" />
              </div>
            </Link>
          </Col>
        </Row>
        <SearchForm />
      </Container>
    </>
  )
}

export default Search
