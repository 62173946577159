import { useState, useRef, lazy, Suspense, useEffect } from 'react'
import {
  Card,
  Collapse,
} from 'reactstrap';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';


const Lastfive = ({ data, home, away }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [HomeImg, setHomeImg] = useState("")
  const [AwayImg, setAwayImg] = useState("")
  let golsPercs = useRef(0)
  let GameResult = useRef(0)
  const toggle = () => setIsOpen(!isOpen);


  useEffect(() => {
    try {
      const homeSrc = require(`../../../assets/players_imgs/${home.toLowerCase()}.png`)
      setHomeImg(homeSrc)
    }
    catch (error) {
      setHomeImg(require("../../../assets/players_imgs/placeholder.png"))
    }
    try {
      const awaySrc = require(`../../../assets/players_imgs/${away.toLowerCase()}.png`)
      setAwayImg(awaySrc)
    }
    catch (error) {
      setAwayImg(require("../../../assets/players_imgs/placeholder.png"))
    }
  }, [])

  const AvarageGolsPercs = () => {
    golsPercs.current = ((Number(data.goalAvarageHome) / (Number(data.goalAvarageHome) + Number(data.goalAvarageAway))) * 100)
  }
  AvarageGolsPercs()

const GameWinner = (game, player) => {
    var value = game.score;
    if (!value) {
        return;
        let homeScore = 0;
        let awayScore = 0;
    } else {
        let homeScore = value.split("-")[0];
        let awayScore = value.split("-")[1];

        if (homeScore > awayScore && player == game.home_player) {
            let result = "home win";
            GameResult.current = result;
        } else if (homeScore < awayScore && player == game.home_player) {
            let result = "away win";
            GameResult.current = result;
        } else {
            let result = "draw";
            GameResult.current = result;
        }
    }
};

  return (
    <Card className="lastfive">
      {isOpen ?
        <button onClick={toggle} className="visibility_headtohead ">
          <VisibilityIcon style={{ width: "15px", height: "15px" }} />
        </button> :
        <button onClick={toggle} className="visibility_headtohead_closed ">
          <VisibilityOffIcon style={{ width: "15px", height: "15px" }} />
        </button>
      }
      {!isOpen &&
        <div>
          <h4 className="headtohead_title">Últimas Cinco</h4>
        </div>
      }
      <Collapse isOpen={isOpen} className="lastfive-content">
        <div className="homeImg">
          {HomeImg.default &&
            <img src={HomeImg.default} alt={`Foto do jogador ${home}`} />}
        </div>
        <div className="lastfive-home">
          <h2>Últimas 5</h2>
          <ul>
            {data.lastFiveAway &&
              <>
                {data.lastFiveAway.map((games => {
                  return (
                    <li onLoad={GameWinner(games, games.home_player)} key={games.id}>
                      ({games.home_player})
                      
                      <span>{`${games.score.split('-')[0]}`}</span>
x
                      {/*GameResult.current === "home win" ?
                        <span className="high_chance result" >V</span> :
                        GameResult.current === "away win" ?
                          <span className="low_chance result" >D</span> :
                          <span className="medium_chance result" >E</span>*/}
                      {/*games.score ?
                        <>
                          
                          <span>{`${games.score.split('-')[1]}`}</span>
                        </> :
                        <span className="low_chance">--</span>*/}

                      <span>{`${games.score.split('-')[1]}`}</span>

                        ({games.away_player})
                    </li>
                  )
                }))}
              </>
            }
          </ul>
        </div>
        <div className="awayImg">
          {AwayImg.default &&
            <img src={AwayImg.default} alt={`Foto do jogador ${away}`} />}
        </div>
        <div className="lastfive-away">
          <h2>Últimas 5</h2>
          <ul>
            {data.lastFiveHome &&
              <>
                {data.lastFiveHome.map((games => {
                  return (
                    <li onLoad={GameWinner(games, games.away_player)} key={games.id}>
                      ({games.home_player})
                      
                      <span>{`${games.score.split('-')[0]}`}</span>
x
                      {/*GameResult.current === "home win" ?
                        <span className="high_chance result" >V</span> :
                        GameResult.current === "away win" ?
                          <span className="low_chance result" >D</span> :
                          <span className="medium_chance result" >E</span>*/}
                      {/*games.score ?
                        <>
                          
                          <span>{`${games.score.split('-')[1]}`}</span>
                        </> :
                        <span className="low_chance">--</span>*/}

                      <span>{`${games.score.split('-')[1]}`}</span>

                        ({games.away_player})
                    </li>
                  )
                }))}
              </>
            }
          </ul>
        </div>
      </Collapse>
    </Card>
  )
}

export default Lastfive
