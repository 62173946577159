import { useRef, useState, useEffect } from 'react';
import League8Logo from '../../../assets/esports-battle-8.png'
import League10Logo from '../../../assets/CLA.png'
import League12Logo from '../../../assets/Logo Fifa 12.png'
import League12GTLogo from '../../../assets/GT-logo.png'
import axios from 'axios';
import { Hint } from 'react-autocomplete-hint';
import { Formik, Field, Form } from 'formik';
import { useHistory } from 'react-router-dom';
import { _10_minutes_league, _12_minutes_league, _8_minutes_league, _12_minutes_gt_league } from '../../Dashboard/components/httpRequests/getMatches'
// import { devRoute } from '../../Dashboard/components/httpRequests/getMatches';
import { productionRoute } from '../../Dashboard/components/httpRequests/getMatches';

const SearchForm = () => {
  const [players, setPlayers] = useState([])
  const [leaguePlayers, setLeaguePlayer ]= useState(false)
  const [options, setOptions] = useState([])
  const playersLower = useRef("") 
  const league = useRef("")
  const history = useHistory();


  const LowerCaseArr = (filtered) => {
    const lowerCaseplayers = filtered.map(player => player.name.toLowerCase()) 
    playersLower.current = lowerCaseplayers
    console.log(playersLower.current, 'playersLower')
    setOptions(playersLower.current)
  } 
  const apiRoute = `${productionRoute}players`


  const handleLeagueError = () => {
    setLeaguePlayer(false)
  }

  const handleLeague8 = () => {
    league.current = _8_minutes_league
    const eightMinGames = players.filter(function (el) {
      return el.league === _8_minutes_league
    });
    LowerCaseArr(eightMinGames)
  }
  const handleLeague10 = () => {
    league.current = _10_minutes_league
    const tenMinGames = players.filter(function (el) {
      return el.league === _10_minutes_league
    });
    LowerCaseArr(tenMinGames)

  }
  const handleLeague12GT= () => {
    league.current = _12_minutes_gt_league
    const GTLeagueGames = players.filter(function (el) {
      return el.league === _12_minutes_gt_league
    });
    LowerCaseArr(GTLeagueGames)

  }
  const handleLeague12 = () => {
    league.current = _12_minutes_league
    const twelveMinGames = players.filter(function (el) {
      return el.league === _12_minutes_league
    });
    LowerCaseArr(twelveMinGames)

  }

  const getPlayers = async () => {
    axios.defaults.headers.get['Content-Type'] = 'application/json;charset=utf-8';
    axios.defaults.headers.get['Access-Control-Allow-Origin'] = '*';
    try {

      let res = await axios.get(apiRoute)
      // console.log(payload, 'payload')
      let playersRes = res.data
      // console.log(playersRes, 'playersRes')
      setPlayers(playersRes)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getPlayers()
  }, [])

  return (
    <Formik
      initialValues={{ home: "", away: "", league: league.current }}
      onSubmit={(values) => {
        if (!playersLower.current.includes(values.home.toLowerCase()) || !playersLower.current.includes(values.away.toLowerCase())) {
          setLeaguePlayer(true) 
        } else {
          history.push('/pages/headtoheadgame', {
            home: values.home.toLowerCase(),
            away: values.away.toLowerCase(),
            league: league.current
          })
        }
      }}
      validate={values => {
        let errors = {};
        if (!values.home) {
          errors.home = "Obrigatório!";
        }
        if (!values.away) {
          errors.away = "Obrigatório";
        }
        else if (values.home.toLowerCase() === values.away.toLowerCase()) {
          errors.away = "Home e Away são o mesmo jogador";
        }
        if (!league.current) {
          errors.league = "Selecione uma Liga!";
        }

        return errors;
      }}
    >
      {props => {
        const {
          values,
          errors,
          handleChange,
          handleSubmit
        } = props;

        return (
          <Form onSubmit={handleSubmit} className='search-headtohead'>
            <div id="my-radio-group" />
            <div role="group" aria-labelledby="my-radio-group" className="searchLeague" aria-required>
              <h1>Selecione a Liga</h1>
              <div className="leagueSelect">
                <label htmlFor="league" for="league8">
                  <input type="radio" onClick={handleLeague8} name="league" id="league8" value={_8_minutes_league} className="checkmark" />
                  <img src={League8Logo} onClick={handleLeague8} alt="logo liga 8min" className="searchLogo" value={_8_minutes_league} />
                </label>
                <label htmlFor="league" for="league10">
                  <input type="radio" onClick={handleLeague10} name="league" id="league10" value={_10_minutes_league} className="checkmark" />
                  <img src={League10Logo} onClick={handleLeague10} alt="logo liga 10min" className="searchLogo" value={_10_minutes_league} />
                </label>
                <label htmlFor="league" for="league12">
                  <input type="radio" onClick={handleLeague12} name="league" id="league12" value={_12_minutes_league} className="checkmark" />
                  <img src={League12Logo} onClick={handleLeague12} alt="logo liga 12min" className="searchLogo" value={_12_minutes_league} />
                </label>
                <label htmlFor="league" for="league12GT">
                  <input type="radio" onClick={handleLeague12GT} name="league" id="league12" value={_12_minutes_gt_league} className="checkmark" />
                  <img src={League12GTLogo} onClick={handleLeague12GT} alt="logo liga 12min" className="searchLogo" value={_12_minutes_gt_league} />
                </label>
              </div>
              {errors.league &&
                <div style={{ color: "red" }} >{errors.league}</div>}
            </div>
            <div className="searchInput">
              <label htmlFor="home" className="search-home">
                <Hint options={options} allowTabFill="true" >
                  <input
                    autocomplete="off"
                    placeholder="Home"
                    type="text"
                    name="home"
                    id="home"
                    onChange={handleChange}

                    value={values.home}
                    className="search"
                  />
                </Hint>
                {errors.home &&
                  <div style={{ color: "red" }}>{errors.home}</div>}
              </label>
              <label htmlFor="away" className=" search-away">
                <Hint options={options} allowTabFill="true">
                  <input
                    autocomplete="off"
                    placeholder="Away"
                    type="text"
                    name="away"
                    id="away"
                    onChange={handleChange}
                    value={values.away}
                    className="search"
                  />
                </Hint>
                {errors.away &&
                  <div style={{ color: "red", width: "fit-content", position: "absolute" }}>{errors.away}</div>}
                 {leaguePlayers === true &&
                  <div style={{ color: "red", width: "fit-content", position: "absolute", marginTop: "20px"  }}>Jogadores de ligas diferentes!</div>} 
              </label>
              <button type="submit" className="submit_head" >
                Pesquisar
              </button>
            </div>
          </Form>
        );
      }}
    </Formik>
  )
}

export default SearchForm
