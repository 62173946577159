import { useState, useRef } from 'react'
import {
  Card,
  Collapse,
} from 'reactstrap';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';


const General = ({ data, home, away }) => {
  const [isOpen, setIsOpen] = useState(true);
  let golsPercs = useRef(0)
  let GameResult = useRef(0)
  const toggle = () => setIsOpen(!isOpen);

  const AvarageGolsPercs = () => {

    golsPercs.current = ((Number(data.goalAvarageHome) / (Number(data.goalAvarageHome) + Number(data.goalAvarageAway))) * 100)

  }


  AvarageGolsPercs()

  const GameWinner = (value) => {
    if (!value) {
      return
      let homeScore = 0
      let awayScore = 0
    } else {
      let homeScore = value.split('-')[0];
      let awayScore = value.split('-')[1];
      if (homeScore > awayScore) {
        let result = "home win"
        GameResult.current = result
      } else if (homeScore < awayScore) {
        let result = "away win"
        GameResult.current = result
      } else {
        let result = "draw"
        GameResult.current = result
      }
    }
  }

  return (
    <Card className="general">
      {isOpen ?
        <button onClick={toggle} className="visibility_headtohead ">
          <VisibilityIcon style={{ width: "15px", height: "15px" }} />
        </button> :
        <button onClick={toggle} className="visibility_headtohead_closed ">
          <VisibilityOffIcon style={{ width: "15px", height: "15px" }} />
        </button>
      }
      {!isOpen &&
        <div>
          <h4 className="headtohead_title">Estatistícas Gerais</h4>
        </div>
      }
      <Collapse isOpen={isOpen} className="general-content">
        <div className="bars_div">
          <h2>Estatistícas Gerais</h2>
          <div className="general-progress">
            <div>
              <div className="progress-titles">
                <h3>Vitória Home</h3>
                <h3>Empate</h3>
                <h3>Vitória Away</h3>
              </div>
              <div class="progress-general">
                {data.homeWinsPerc &&
                  <>
                    {data.awayWinsPerc >= 10 &&
                      <p>{(data.awayWinsPerc).toFixed(2)}%</p>}
                    <div class="progress-done-general" style={{ width: `${data.homeWinsPerc}%` }}>
                      {data.homeWinsPerc >= 10 &&
                        <span>{(data.homeWinsPerc).toFixed(2)}%</span>}
                    </div  >
                    {(100 - (data.homeWinsPerc + data.awayWinsPerc)) >= 0 &&
                      <div class="progress-draw" style={{ width: `${100 - (data.homeWinsPerc + data.awayWinsPerc)}%` }} >
                        {100 - (data.homeWinsPerc + data.awayWinsPerc) >= 10 &&
                          <span>{`${(100 - (data.homeWinsPerc + data.awayWinsPerc)).toFixed(2)}`}%</span>}
                      </div>}
                  </>}
              </div>
            </div>
            <div>
              <div className="progress-titles">
                <h3>Sim</h3>
                <h3>Ambas Marcam</h3>
                <h3>Não</h3>
              </div>
              <div class="progress-general">
                {/* Dados invertidos aguardando bug fix */}
                {data.bothScoreYes &&
                  <>
                    <p>{(data.bothScoreYes).toFixed(2)}%</p>
                    <div class="progress-done-general" style={{ width: `${(100 - data.bothScoreYes).toFixed(2)}%` }}>
                      <span>{`${(100 - data.bothScoreYes).toFixed(2)}%`}</span>
                    </div>
                  </>}
              </div>
            </div>
            <div>
              <div className="progress-titles">
                <h3 aria-hidden="true"></h3>
                <h3>Média de Gols</h3>
                <h3 aria-hidden="true"></h3>
              </div>
              <div class="progress-general">
                {(data.goalAvarageHome && data.goalAvarageAway) &&
                  <>
                    <p>{(data.goalAvarageAway.toFixed(2))}</p>
                    <div class="progress-done-general" style={{ width: `${golsPercs.current}%` }}>
                      <span>{(data.goalAvarageHome).toFixed(2)}</span>
                    </div>
                  </>}
              </div>
            </div>
          </div>
        </div>
      </Collapse>
    </Card>
  )
}

export default General
