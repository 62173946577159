
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';
import CalculateOutlinedIcon from '@mui/icons-material/Calculate';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import ConstructionOutlinedIcon from '@mui/icons-material/ConstructionOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import SensorsOutlinedIcon from '@mui/icons-material/SensorsOutlined';
import SportsEsportsOutlinedIcon from '@mui/icons-material/SportsEsportsOutlined';
import SportsSoccerOutlinedIcon from '@mui/icons-material/SportsSoccerOutlined';
import VideoLibraryOutlinedIcon from '@mui/icons-material/VideoLibraryOutlined';
import PestControlOutlinedIcon from '@mui/icons-material/PestControlOutlined';
import PlaylistPlayOutlinedIcon from '@mui/icons-material/PlaylistPlayOutlined';
import VersusIcon from '../../../assets/versus.png'

const SidebarContent = ({ onClick }) => {
  const handleHideSidebar = () => {
    onClick();
  };

  return (
    <div className="sidebar__content">
      <ul className="sidebar__block">
        {/* <SidebarCategory title="Pages" icon="list"> */}
          <SidebarLink title="Live" route="/pages/live" onClick={handleHideSidebar} />
          <SensorsOutlinedIcon style={{zIndex: "10", color: "#FFF", position: "absolute", left: "12px", top: '45px', opacity: "0.5", width: "27px", height: "27px", pointerEvents: "none"}} />
          <SidebarLink title="Head to Head" route="/pages/headtohead" onClick={handleHideSidebar}/>
          <img src={VersusIcon} alt="ícone de versus" style={{zIndex: "10", color: "#FFF", position: "absolute", left: "12px", top: '92px', width: "28px", height: "28px", pointerEvents: "none"}} />
          <SidebarLink title="Dutching" route="/pages/dutching" onClick={handleHideSidebar}  /> 
          <CalculateOutlinedIcon style={{zIndex: "10", color: "#FFF", position: "absolute", left: "12px", top: '144px', opacity: "0.5", width: "27px", height: "27px", pointerEvents: "none"}}  />
          <SidebarLink title="Próximos Jogos" route="/pages/nextgames" onClick={handleHideSidebar} rel="noreferrer noopener"/>
          <EventNoteOutlinedIcon style={{zIndex: "10", color: "#FFF", position: "absolute", left: "12px", top: '195px', opacity: "0.5", width: "27px", height: "27px", pointerEvents: "none"}}/>
          <a className="sidebar__link" href="https://www.youtube.com/playlist?list=PLSgtXtZFVtW7Wi2YPTxVIVlTCgTSiihnO"  target="_blank" rel="noreferrer noopener">
          <p className='sidebar__link-title'>Treinamento</p></a>  
          <PlaylistPlayOutlinedIcon style={{zIndex: "10", color: "#FFF", position: "absolute", left: "12px", top: '246px', opacity: "0.5", width: "27px", height: "27px", pointerEvents: "none"}}/>
          <a className="sidebar__link" href="https://t.me/jesustipsfree"  target="_blank" rel="noreferrer noopener">
          <p className='sidebar__link-title'>Grupo Free</p></a>  
          <GroupsOutlinedIcon style={{zIndex: "10", color: "#FFF", position: "absolute", left: "12px", top: '295px', opacity: "0.5", width: "27px", height: "27px", pointerEvents: "none"}}/>
          <a className="sidebar__link" href="https://jtinvestimentos.com/"  target="_blank" rel="noreferrer noopener">
          <p className='sidebar__link-title'>Sala de sinais</p></a>
          <MonetizationOnOutlinedIcon style={{zIndex: "10", color: "#FFF", position: "absolute", left: "12px", top: '344px', opacity: "0.5", width: "27px", height: "27px", pointerEvents: "none"}}/>  
          <a className="sidebar__link" href="https://www.youtube.com/watch?v=c_g-jQvkUrQ"  target="_blank" rel="noreferrer noopener">
          <p className='sidebar__link-title'>Sobre a ferramenta</p></a>  
          <VideoLibraryOutlinedIcon style={{zIndex: "10", color: "#FFF", position: "absolute", left: "12px", top: '395px', opacity: "0.5", width: "27px", height: "27px", pointerEvents: "none"}}/>
          <a className="sidebar__link" href="https://t.me/suporteJT"  target="_blank" rel="noreferrer noopener">
          <p className='sidebar__link-title'>Suporte</p></a> 
          <SupportAgentOutlinedIcon style={{zIndex: "10", color: "#FFF", position: "absolute", left: "12px", top: '445px', opacity: "0.5", width: "27px", height: "27px", pointerEvents: "none"}}/>
          <a className="sidebar__link" href="https://t.me/+s4GxOhqqTaRjNzUx"  target="_blank" rel="noreferrer noopener">
          <p className='sidebar__link-title'>Reporte Bugs</p></a> 
          <PestControlOutlinedIcon style={{zIndex: "10", color: "#FFF", position: "absolute", left: "12px", top: '495px', opacity: "0.5", width: "27px", height: "27px", pointerEvents: "none"}}/>
        {/* </SidebarCategory> */}
      </ul>
    </div>
  );
};



export default SidebarContent;
