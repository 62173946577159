import {
  CardTitle,
  Modal,
} from 'reactstrap';
import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Dutching from './Dutching';
import CardTimer from './CardTimer';
import betLogo from '../../../assets/bet-365-ico.png'
import betanoLogo from '../../../assets/betano-logo.png'
import CardScore from './CardScore';
import League8Logo from '../../../assets/esports-battle-8.png'
import League10Logo from '../../../assets/CLA.png'
import League12Logo from '../../../assets/Logo Fifa 12.png'
import LeagueGT12Logo from '../../../assets/GT-logo.png'
import { _10_minutes_league, _12_minutes_league, _8_minutes_league, _12_minutes_gt_league } from '../components/httpRequests/getMatches'

const CardHeader = ({ match }) => {
  const [modal, setModal] = useState(false)
  const [HomeImg, setHomeImg] = useState("")
  const [AwayImg, setAwayImg] = useState("")


  // console.log(`${match.home.name} ${match.home.score} - ${match.away.score}  ${match.away.name} `)
  // console.log(match.away.score, match.away.name )

  useEffect(() => {
    try {
      const homeSrc = require(`../../../assets/players_imgs/${match.home.name.toLowerCase()}.png`)
      setHomeImg(homeSrc)
    }
    catch (error) {
      setHomeImg(require("../../../assets/players_imgs/placeholder.png"))
    }
    try {
      const awaySrc = require(`../../../assets/players_imgs/${match.away.name.toLowerCase()}.png`)
      setAwayImg(awaySrc)
    }
    catch (error) {
      setAwayImg(require("../../../assets/players_imgs/placeholder.png"))
    }


  }, [])

  const attacks = match ? match.attacks ? Number(match.attacks.home) / (Number(match.attacks.home) + Number(match.attacks.away)) * 100 : 0 : 0
  const dangAttacks = match ? match.dangerousattacks ? Number(match.dangerousattacks.home) /
    (Number(match.dangerousattacks.home) + Number(match.dangerousattacks.away)) * 100 : 0 : 0

  const toggleModal = () => setModal(!modal);

  const preventDragHandler = (e) => {
    e.preventDefault();
  }

  return (
    <CardTitle className="card__title">
      {match &&
        <>
          <div className="card_header">
            {match.league === _10_minutes_league ?
              <img src={League10Logo} alt="Logo liga 10min" className="logoLiga" /> : 
              match.league === _8_minutes_league ?
              <img src={League8Logo} alt="Logo liga 8min" className="logoLiga" />: 
              match.league === _12_minutes_league ?
              <img src={League12Logo} alt="Logo liga 12min" className="logoLiga"/> :
              <img src={LeagueGT12Logo} alt="Logo liga GT 12min" className="logoLiga"/>}

            <div className="players">
              <div className="players-grid">
                {HomeImg.default  && match &&
                  <img src={HomeImg.default}
                    alt={`Foto do jogador ${match?.home?.name}`}
                    className="homeHeaderImg"
                    onDragStart={preventDragHandler} />}
                {match.home && match.away &&
                <div style={{display: 'flex', justifyContent: 'center'}}>
                <h2 className="bold-text">
                  <Link to={{
                    pathname: '/pages/headtoheadgame',
                    state: {
                      match: match,
                      league: match.league
                    }
                  }}
                    className="card-link">
                    {match.home.name}
                    <CardScore home={match.home.score} away={match.away.score} id={match.id}/>
                    {match.away.name}
                    <h3 className="subhead">({match.home.team} <span className='divisor'>X</span>  {match.away.team})</h3>
                  </Link>
                </h2>
                </div>}
                {AwayImg.default &&
                  <img src={AwayImg.default}
                    alt={`Foto do jogador ${match?.away?.name}`}
                    className="awayHeaderImg"
                    onDragStart={preventDragHandler} />}
              </div>
              {match.prelive === true ? <div className='prelive'>
                      <h4>Pré-live</h4> </div> :
                     <CardTimer minute={match.time} seconds={match.seconds}/>
                    } 
              <div style={{ display: "flex", margin: "0 auto", width: "10vw" }}>
                <button onClick={toggleModal} className="modal_btn"  >
                  <p>Dutching </p>
                  <Modal isOpen={modal} toggle={toggleModal} >
                    <Dutching match={match} home={match.home} away={match.away} />
                  </Modal>
                </button>

                {match.link365 ? 
                <button className="bet_btn">
                <a target='_blank' href={match.link365}>
                  <img src={betLogo} alt="Logo Bet365" />
                </a>
              </button> :
              <button className="bet_btn" style={{opacity: "0.5", cursor: "not-allowed"}}>
              <a target='_blank' href={match.link365}>
                <img src={betLogo} alt="Logo Bet365"  />
              </a>
            </button>
                }  
              </div>
              {match.qtdgames && 
                    <div className='pvpmatchs'><h4>Confrontos: {match.qtdgames}</h4></div>
                    } 
            </div>
          </div>
          
          <div className="progressbars">
            <div className="attacks">
              {match.attacks &&
                <>
                  <div className="progress-data">{match.attacks.home}</div>
                  <div class="progress">
                    <h3>Ataques</h3>
                    <div class="progress-done"
                      {...attacks > 0 ? { style: { width: `${attacks}%` } } : { style: { width: "50%" } }} />
                  </div>
                  <div>
                    <div className="progress-data">{match.attacks.away}</div>
                  </div>
                </>}
              {match.dangerousattacks &&
                <>
                  <div className="progress-data">{match.dangerousattacks.home}</div>
                  <div class="progress">
                    <p>Ataques Perigosos</p>
                    <div class="progress-done"
                      {...dangAttacks > 0 ? { style: { width: `${dangAttacks}%` } } : { style: { width: "50%" } }} />
                  </div>
                  <div className="progress-data">{match.dangerousattacks.away}</div>
                </>}
            </div>
            
          </div>
        </>}
    </CardTitle>
  )
}

export default CardHeader
