import { useState, useEffect, useRef } from 'react'
import {
  Card,
  Collapse,
} from 'reactstrap';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Pagination from "react-js-pagination";
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import LeagueLogo from '../../assets/Logo Fifa 12.png'
import NextGames from '../components/NextGames';


const Fifa12 = ({ date, nextGames }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [activePage, setPages] = useState(0)

  const LeagueName = "Esoccer Liga Pro - 12 mins play"
  // console.log(nextGames)
  const TwelveMinGames = nextGames.filter(function (el) {
    return el.league.name === LeagueName
  }
  );
  const toggle = () => setIsOpen(!isOpen);

  const handlePage = (pageNumber) => {
    setPages(pageNumber)
  }

  const gamesVisited = 5 * activePage


  return (
    <Card className="toHead">
      {isOpen ?
        <button onClick={toggle} className="visibility_headtohead ">
          <VisibilityIcon style={{ width: "15px", height: "15px" }} />
        </button> :
        <button onClick={toggle} className="visibility_headtohead_closed ">
          <VisibilityOffIcon style={{ width: "15px", height: "15px" }} />
        </button>
      }
      {!isOpen &&
        <div>
          <h4 className="headtohead_title">Liga Pro (12 min)</h4>
        </div>
      }
      <Collapse isOpen={isOpen} className="toHead-content">
        <div className="nextgames-header">
          <h2>Liga Pro (12 min)</h2>
          <div className="LeagueLogo" >
            <img src={LeagueLogo} alt="Logo da liga Cyber live arena" />
          </div>
        </div>
        <div className="nextGames-list">
          <div className='liga12 nextgames-liga'>FIFA 12 MIN - LP </div>
          <ul>
            {TwelveMinGames &&
              <>
                {TwelveMinGames.slice(gamesVisited, gamesVisited + 5).map((games => {
                  return (
                    <NextGames home={games.home.name} away={games.away.name} time={games.time} date={date} />
                  )
                }))}
              </>}
          </ul>
        </div>
        {TwelveMinGames.length >=  4 ?
        <div className="pages">
          <ul>
            <Pagination
              activePage={activePage}
              ite msCountPerPage={5}
              totalItemsCount={(TwelveMinGames.length-5)}
              pageRangeDisplayed={5}
              onChange={handlePage}
            />
          </ul>
        </div> : <></>}
      </Collapse>
    </Card>
  )
}

export default Fifa12
