import { useState, useEffect, useRef } from 'react'
import axios from 'axios';
import { Redirect } from 'react-router';
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  CardTitle,
} from 'reactstrap';
// import Logo from '../assets/JT-manager-logo.png'
import Logo from '../assets/Logo_beta.png'
import Fifa8 from './components/Fifa8';
import Fifa10 from './components/Fifa10';
import Fifa12 from './components/Fifa12';
import FifaGT from './components/FifaGT'
import { Link } from 'react-router-dom';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { devRoute } from '../containers/Dashboard/components/httpRequests/getMatches';
import { productionRoute } from '../containers/Dashboard/components/httpRequests/getMatches';

const components = [
  {
    'name': Fifa8,
    'id': 1
  },
  {
    'name': Fifa10,
    'id': 2
  },
  {
    'name': Fifa12,
    'id': 3
  }, 
  {
    'name': FifaGT,
    'id': 4
  }
]

const NextGames = () => {
  const [order, setOrder] = useState(components)
  const initState = {
    load: true,
    data: [],
    error: '',
  };
  const [nextGames, setNextGames] = useState(initState);
  const [date, setDate] = useState(0)


  const getDay = () => {
    var today = new Date()
    var year = today.getFullYear()
    var month = (today.getMonth() + 1)
    var day = today.getDate()

    if (day < 10) { var day = '0'+day+''; }
    if (month < 10) { var month = '0'+month+''; }

    setDate(`${day.toString()}/${month.toString()}/${year}`)
  }


  const getNextGames = async () => {

    try {
      axios.defaults.headers.get['Content-Type'] = 'application/json;charset=utf-8';
      axios.defaults.headers.get['Access-Control-Allow-Origin'] = '*';
      axios.defaults.headers.get['Access-Control-Allow-Origin'] = 'GET,PUT,POST,DELETE,PATCH,OPTIONS';

      const res = await axios(`${productionRoute}upcoming/${date} `)

      if (res) {
        const axiosData = res.data;
        setNextGames({ ...nextGames, load: false, data: axiosData });
      }
    } catch (error) {
      setNextGames({ ...nextGames, load: false, error });
    }
  }
  useEffect(() => {
    getDay()
  }, []);

  useEffect(() => {
    getNextGames()
  }, [date]);


  function handleOnDragEnd(result) {
    if (!result.destination) return
    if (result.destination.index !== result.source.index) {
      const items = Array.from(order)
      const [reorderedItems] = items.splice(result.source.index, 1)
      items.splice(result.destination.index, 0, reorderedItems)
      setOrder(items)
    } else {
      const items = Array.from(order)
      setOrder(items)
    }
  }
  return (
    <>
      <Container fluid>
        <Row>
          {!sessionStorage.getItem('AccessToken') &&
            <Redirect to="/" />}
          <Col md={12} >
          <Link to="/pages/live">
            <div className="main-title">
              <img src={Logo} alt="Jesus Tips Logo" className="logo" />
            </div>
            </Link>
          </Col>
        </Row>
      </Container>
      <Col md={12} className="headtohead_grid">
        <Card className="card-body">
          <CardTitle className="header__title">
            <div style={{ padding: "50px" }}>
              <h1 style={{ color: "#FFF600" }}>Próximos Jogos</h1>
            </div>
          </CardTitle>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <CardBody>
              {order.map((component, id) => {
                return (
                  <>
                    <Droppable droppableId={`row${id}`}>
                      {(provider) =>
                        <div ref={provider.innerRef} {...provider.droppableProps}>
                          <Draggable draggableId={`drag${id}`} index={id}>
                            {(provider) =>
                              <div
                                key={id}
                                ref={provider.innerRef}
                                {...provider.draggableProps}
                                {...provider.dragHandleProps}>
                                <component.name date={date} nextGames={nextGames.data} />
                              </div>}
                          </Draggable>
                          {provider.placeholder}
                        </div>}
                    </Droppable>
                  </>)
              })}
            </CardBody>
          </DragDropContext>
        </Card>
      </Col>
    </>
  )
}

export default NextGames
