import { useState } from 'react'
import {
  Card,
  Collapse,
} from 'reactstrap';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded';


const OverUnderHT = ({ data }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isOpen2, setIsOpen2] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const toggle2 = () => setIsOpen2(!isOpen2);

  return (
    <Card className="general">
      {isOpen ?
        <button onClick={toggle} className="visibility_headtohead ">
          <VisibilityIcon style={{ width: "15px", height: "15px" }} />
        </button> :
        <button onClick={toggle} className="visibility_headtohead_closed ">
          <VisibilityOffIcon style={{ width: "15px", height: "15px" }} />
        </button>
      }
      {!isOpen &&
        <div>
          <h4 className="headtohead_title">Over / Under (1ºT)</h4>
        </div>
      }
      <Collapse isOpen={isOpen} className="over-content">
        <div className="bars_div_over">
          <h2>Over / Under (1ºT)</h2>
          {data.oversht &&
            <div className="general-progress">
              <div>
                <div className="progress-titles">
                  <h3>Over</h3>
                  <h3>0,5</h3>
                  <h3>Under</h3>
                </div>
                <div className="progress-over">
                  {data['oversht']['0.5'] &&
                    <>
                      <p>{`${(100 - data['oversht']['0.5']).toFixed(2)}%`} </p>
                      <div className="progress-done-over" style={{ width: `${data['oversht']['0.5']}%` }}>
                        <span>{data['oversht']['0.5']}%</span>
                      </div>
                    </>}
                </div>
              </div>
              <div>
                <div className="progress-titles">
                  <h3>Over</h3>
                  <h3>1,5</h3>
                  <h3>Under</h3>
                </div>
                <div className="progress-over">
                  {data['oversht']['1.5'] &&
                    <>
                      <p>{`${(100 - data['oversht']['1.5']).toFixed(2)}%`} </p>
                      <div className="progress-done-over" style={{ width: `${data['oversht']['1.5']}%` }}>
                        <span>{data['oversht']['1.5']}%</span>
                      </div>
                    </>}
                </div>
              </div>
              <div>
                <div className="progress-titles">
                  <h3>Over</h3>
                  <h3>2,5</h3>
                  <h3>Under</h3>
                </div>
                <div class="progress-over">
                  {data['oversht']['2.5'] &&
                    <>
                      <p>{`${(100 - data['oversht']['2.5']).toFixed(2)}%`} </p>
                      <div className="progress-done-over" style={{ width: `${data['oversht']['2.5']}%` }}>
                        <span>{data['oversht']['2.5']}%</span>
                      </div>
                    </>}
                </div>
              </div>
              <div>
                <div className="progress-titles">
                  <h3>Over</h3>
                  <h3>3,5</h3>
                  <h3>Under</h3>
                </div>
                <div class="progress-over">
                  {data['oversht']['3.5'] &&
                    <>
                      <p>{`${(100 - data['oversht']['3.5']).toFixed(2)}%`} </p>
                      <div className="progress-done-over" style={{ width: `${data['oversht']['3.5']}%` }}>
                        <span>{data['oversht']['3.5']}%</span>
                      </div>
                    </>}
                </div>
              </div>
              <div>
                <div className="progress-titles">
                  <h3>Over</h3>
                  <h3>4,5</h3>
                  <h3>Under</h3>
                </div>
                <div class="progress-over">
                  {data['oversht']['4.5'] &&
                    <>
                      <p>{`${(100 - data['oversht']['4.5']).toFixed(2)}%`} </p>
                      <div className="progress-done-over" style={{ width: `${data['oversht']['4.5']}%` }}>
                        <span>{data['oversht']['4.5']}%</span>
                      </div>
                    </>}
                </div>
              </div>
              <div>
                <div className="progress-titles">
                  <h3>Over</h3>
                  <h3>5,5</h3>
                  <h3>Under</h3>
                </div>
                <div class="progress-over">
                  {data['oversht']['5.5'] &&
                    <>
                      <p>{`${(100 - data['oversht']['5.5']).toFixed(2)}%`} </p>
                      <div className="progress-done-over" style={{ width: `${data['oversht']['5.5']}%` }}>
                        <span>{data['oversht']['5.5']}%</span>
                      </div>
                    </>}
                </div>
              </div>
            </div>}
          {isOpen2 ?
            <button onClick={toggle2} className="sub-toggle-close">
              <ExpandLessRoundedIcon style={{ width: "15px", height: "15px" }} />
            </button> :
            <button onClick={toggle2} className="sub-toggle-open">
              <ExpandMoreRoundedIcon style={{ width: "15px", height: "15px" }} />
            </button>}
          <Collapse isOpen={isOpen2}>
            {data.oversht &&
              <div className="subbars_div">
                <div className="general-progress">
                  <div>
                    <div className="progress-titles">
                      <h3>Over</h3>
                      <h3>6,5</h3>
                      <h3>Under</h3>
                    </div>
                    <div className="progress-over">
                      {data['oversht']['6.5'] &&
                        <>
                          <p>{`${(100 - data['oversht']['6.5']).toFixed(2)}%`} </p>
                          <div className="progress-done-over" style={{ width: `${data['oversht']['6.5']}%` }}>
                            <span>{data['oversht']['6.5']}%</span>
                          </div>
                        </>}
                    </div>
                  </div>
                  <div>
                    <div className="progress-titles">
                      <h3>Over</h3>
                      <h3>7,5</h3>
                      <h3>Under</h3>
                    </div>
                    <div class="progress-over">
                      {data['oversht']['7.5'] &&
                        <>
                          <p>{`${(100 - data['oversht']['7.5']).toFixed(2)}%`} </p>
                          <div className="progress-done-over" style={{ width: `${data['oversht']['7.5']}%` }}>
                            <span>{data['oversht']['7.5']}%</span>
                          </div>
                        </>}
                    </div>
                  </div>
                  <div>
                    <div className="progress-titles">
                      <h3>Over</h3>
                      <h3>8,5</h3>
                      <h3>Under</h3>
                    </div>
                    <div class="progress-over">
                      {data['oversht']['8.5'] &&
                        <>
                          <p>{`${(100 - data['oversht']['8.5']).toFixed(2)}%`} </p>
                          <div className="progress-done-over" style={{ width: `${data['oversht']['8.5']}%` }}>
                            <span>{data['oversht']['8.5']}%</span>
                          </div>
                        </>}
                    </div>
                  </div>
                  <div>
                    <div className="progress-titles">
                      <h3>Over</h3>
                      <h3>9,5</h3>
                      <h3>Under</h3>
                    </div>
                    <div class="progress-over">
                      {data['oversht']['9.5'] &&
                        <>
                          <p>{`${(100 - data['oversht']['9.5']).toFixed(2)}%`} </p>
                          <div className="progress-done-over" style={{ width: `${data['oversht']['9.5']}%` }}>
                            <span>{data['oversht']['9.5']}%</span>
                          </div>
                        </>}
                    </div>
                  </div>
                  <div>
                    <div className="progress-titles">
                      <h3>Over</h3>
                      <h3>10,5</h3>
                      <h3>Under</h3>
                    </div>
                    <div class="progress-over">
                      {data['oversht']['10.5'] &&
                        <>
                          <p>{`${(100 - data['oversht']['10.5']).toFixed(2)}%`} </p>
                          <div className="progress-done-over" style={{ width: `${data['oversht']['10.5']}%` }}>
                            <span>{data['oversht']['10.5']}%</span>
                          </div>
                        </>}
                    </div>
                  </div>
                </div>
              </div>}
          </Collapse>
        </div>
      </Collapse>
    </Card>
  )
}

export default OverUnderHT
