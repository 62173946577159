import { useState, useEffect, useRef } from 'react'
import {
  Card,
  Collapse,
} from 'reactstrap';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Pagination from "react-js-pagination";
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import LeagueLogo from '../../assets/CLA.png'
import NextGames from '../components/NextGames';
import { _10_minutes_league } from '../../containers/Dashboard/components/httpRequests/getMatches';


const Fifa10 = ({ date, nextGames }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [activePage, setPages] = useState(1)

  const tenMinGames = nextGames.filter(function (el) {
    return el.league.name === _10_minutes_league
  }
  );
  const toggle = () => setIsOpen(!isOpen);

  const handlePage = (pageNumber) => {
    setPages(pageNumber)
  }

  const gamesVisited = activePage === 1 ? 0 : 5 * activePage


  return (
    <Card className="toHead">
      {isOpen ?
        <button onClick={toggle} className="visibility_headtohead ">
          <VisibilityIcon style={{ width: "15px", height: "15px" }} />
        </button> :
        <button onClick={toggle} className="visibility_headtohead_closed ">
          <VisibilityOffIcon style={{ width: "15px", height: "15px" }} />
        </button>
      }
      {!isOpen &&
        <div>
          <h4 className="headtohead_title">Cyber Live Arena (10 min)</h4>
        </div>
      }
      <Collapse isOpen={isOpen} className="toHead-content">
        <div className="nextgames-header">
          <h2>Cyber Live Arena (10 min)</h2>
          <div className="LeagueLogo" >
            <img src={LeagueLogo} alt="Logo da liga Cyber live arena" />
          </div>
        </div>
        <div className="nextGames-list">
          <div className='liga10 nextgames-liga'>FIFA 10 MIN - CLA </div>
          <ul>
            {tenMinGames &&
              <>
                {tenMinGames.slice(gamesVisited, gamesVisited + 5).map((games => {
                  return (
                    <NextGames home={games.home.name} away={games.away.name} time={games.time} date={date} league={games.league.name}/>
                  )
                }))}
              </>}
          </ul>
        </div>
        {tenMinGames.length >= 5 ?  
        <div className="pages">
          <ul>
            <Pagination
              activePage={activePage}
              itemsCountPerPage={5}
              totalItemsCount={(tenMinGames.length-5)}
              pageRangeDisplayed={5}
              onChange={handlePage}
            />
          </ul>
        </div> : <></>}
      </Collapse>
    </Card>
  )
}

export default Fifa10
