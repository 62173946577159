import { Container } from 'reactstrap';
import { useState, useEffect } from 'react';
import Loading from '../../../assets/LOADING2.gif'

const ErrorMsg = () => {
  return (
    <Container fluid>
      <div className="NoMatchs">
        <h1>Procurando por partidas nesse momento</h1>
        <img src={Loading} alt="loading" />
      
      </div>
    </Container>
  )
}

// const ErrorMsg = () => {
//   const [randomQuote, setQuote] = useState('')
//   const quote = [
//     "Oolancer fez tantos gols que nossos servidores não processaram ",
//     "Praym amarrou tanto o jogo que nossos servidores não conseguiram processar",
//     "Spursmax tomou tantos gols que não conseguimos contar"
//   ]

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setQuote(quote[Math.floor(Math.random() * quote.length)])
//     }, 5000);
//     return () => clearInterval(interval);

//   }, []);

//   return (
//     <Container fluid>

//       <div style={{ width: "30vw", margin: "150px auto" }}>
//         <h1 className="errorMsg">{randomQuote}</h1>
//       </div>
//     </Container>
//   )
// }

export default ErrorMsg