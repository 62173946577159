
import { ModalBody, ModalHeader } from 'reactstrap';
import League10minLogo from '../../assets/CLA.png'
import League8minLogo from '../../assets/esports-battle-8.png'
import League12minLogo from '../../assets/Logo Fifa 12.png'
import LeagueGT12minLogo from '../../assets/GT-logo.png'

const LeagueFilter = ({toggleFifa8, toggleFifa10, Fifa10, Fifa8 , toggleFifa12, Fifa12, Fifa12GT, toggleFifa12GT }) => {

  return (
    <>
      <ModalHeader className="filterHeader">
      <h1>Selecione a Liga</h1>
      </ModalHeader>
      <ModalBody >
        <form className="filterForm">
          <label htmlFor="8min">
          {Fifa8.current ? 
            <input type="checkbox" onClick={toggleFifa8} id="8min" checked className="checkmark"/> : 
            <input type="checkbox" onClick={toggleFifa8} id="8min" className="checkmark"/>}   
            <img src={League8minLogo} alt="Logo Liga 8min" className="filterLogo"  />      
          </label>
          <label htmlFor="10min">
          {Fifa10.current  ? 
            <input type="checkbox" onClick={toggleFifa10} id="10min" checked className="checkmark"/> : 
            <input type="checkbox" onClick={toggleFifa10} id="10min" className="checkmark"/>}   
            <img src={League10minLogo} alt="Logo Liga 10min" className="filterLogo" />
          </label>
          <label htmlFor="12min">
          {Fifa12.current  ? 
            <input type="checkbox" onClick={toggleFifa12} id="12min" checked className="checkmark"/> : 
            <input type="checkbox" onClick={toggleFifa12} id="12min" className="checkmark"/>}   
            <img src={League12minLogo} alt="Logo Liga 12min" className="filterLogo" />
          </label>

          <label htmlFor="12minGT">
          {Fifa12GT.current ? 
            <input type="checkbox" onClick={toggleFifa12GT} id="12minGT" checked className="checkmark"/> : 
            <input type="checkbox" onClick={toggleFifa12GT} id="12minGT" className="checkmark"/>}   
            <img src={LeagueGT12minLogo} alt="Logo Liga GT 12min" className="filterLogo12" />
          </label>

        </form>
      </ModalBody>
    </>
  )
}

export default LeagueFilter
