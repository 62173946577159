import { Switch, Route } from 'react-router-dom'
import Dashboard from '../../Dashboard'
import HeadToHead from '../../HeadToHead'
import Search from '../../HeadToHead/Search'
import LoginVal from '../../LogIn/components/LoginVal'
import DutchingCalc from '../../../Dutching'
import NextGames from '../../../NextGames/index'

const Pages = () => {
    return (
    <Switch> 
      <Route path="/pages/live" component={Dashboard} />
      <Route path="/pages/headtoheadgame" component={HeadToHead} />
      <Route path="/pages/headtohead" component={Search} />
      <Route path="/pages/dutching" component={DutchingCalc} />
      <Route path="/pages/loginv" component={LoginVal} />
      <Route path="/pages/nextgames" component={NextGames} />
    </Switch>
    )
}

export default Pages
