import { useState, useEffect } from "react"

const CardTimer = ({minute, seconds}) => {
  const initalState = 0
  const [matchSeconds, setSeconds ]= useState(initalState)
  const [matchMinutes, setMinutes] = useState(initalState)
  
  // console.log(match, 'card header')
  const getGameSecond =  ()  => {
    if(matchSeconds < 59 ) {
      setSeconds(matchSeconds + 1)
    } else {
      setSeconds(initalState)
      setMinutes(matchMinutes + 1)
    }
  }

  useEffect(() => {
    setSeconds(Number(seconds))
    setMinutes(Number(minute))
  }, [seconds, minute])

  useEffect(() => {
    const interval = setInterval(() => {
      getGameSecond()
    }, 1000);
    return () => clearInterval(interval);
  }, [ matchSeconds]);

  return (
    <>
    {(minute != null || seconds != null )&&
     <h4 className="time">{matchMinutes}' {matchSeconds}''</h4>}
    </>
  )
}

export default CardTimer