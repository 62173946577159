import { useState, useRef } from 'react'
import {
  Card,
  Collapse,
} from 'reactstrap';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Pagination from "react-js-pagination";
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'



const ToHead = ({ data }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [activePage, setPages] = useState(1)
  let GameResult = useRef(0)
  let GameDate = useRef(0)
  const toggle = () => setIsOpen(!isOpen);

  const formatDate = (value) => {
    if (!value) {
      GameDate.current = " "
    } else {
      let date = value.split('-')
      let formatedDate = `${date[0]}/${date[1]}/${date[2]}`
      GameDate.current = formatedDate
    }
  }

  const GameWinner = (value) => {
    if (!value) {
      GameResult.current = 0
    } else {
      let homeScore = value.split('-')[0];
      let awayScore = value.split('-')[1];
      if (homeScore > awayScore) {
        let result = "home win"
        GameResult.current = result
      } else if (homeScore < awayScore) {
        let result = "away win"
        GameResult.current = result
      } else {
        let result = "draw"
        GameResult.current = result
      }
    }
  }

  const handlePage = (pageNumber) => {
    // console.log(`active page is ${pageNumber}`);
    setPages(pageNumber)
  }

  const gamesVisited = activePage === 1 ? 0 : 5 * activePage
  // console.log(data.playerVsPlayer, 'games')
  return (
    <Card className="toHead">
      {isOpen ?
        <button onClick={toggle} className="visibility_headtohead ">
          <VisibilityIcon style={{ width: "15px", height: "15px" }} />
        </button> :
        <button onClick={toggle} className="visibility_headtohead_closed ">
          <VisibilityOffIcon style={{ width: "15px", height: "15px" }} />
        </button>
      }
      {!isOpen &&
        <div>
          <h4 className="headtohead_title">Head to Head</h4>
        </div>
      }
      <Collapse isOpen={isOpen} className="toHead-content">
        <h2>Head to Head</h2>
        <div className="headToHead-list">
          {data.playerVsPlayer &&
            <ul>
              {
                data.playerVsPlayer.slice(gamesVisited, gamesVisited + 5).map((game) => {
                  return (
                    <li key={game.match_id} onLoad={GameWinner(game.score)}>
                      <span className="game-date" onLoad={formatDate(game.date)}>{GameDate.current}</span>
                      <span>&nbsp;</span>
                      {/*GameResult.current === "home win" ?
                        <span className="high_chance result" >V</span> :
                        GameResult.current === "away win" ?
                          <span className="low_chance result" >D</span> :
                          <span className="medium_chance result" >E</span>*/}
                      <span>({game.home_team_name}) {game.home_player}</span>
                      <div className="score-board">
                        {game.score ?
                          <>
                            {/*GameResult.current === "home win" ?
                              <span className="high_chance" >{`${game.score.split('-')[0]}`}</span> :
                              GameResult.current === "away win" ?
                                <span className="low_chance">{`${game.score.split('-')[0]}`}</span> :
                                <span className="medium_chance">{`${game.score.split('-')[0]}`}</span>*/}
                            {`${game.score.split('-')[0]}`}
                            <span>-</span>
                            {`${game.score.split('-')[1]}`}
                            {/*GameResult.current === "away win" ?
                              <span className="high_chance">{`${game.score.split('-')[1]}`}</span> :
                              GameResult.current === "home win" ?
                                <span className="low_chance">{`${game.score.split('-')[1]}`}</span> :
                                <span className="medium_chance">{`${game.score.split('-')[1]}`}</span>*/}
                          </> :
                          <>
                            <span className="medium_chance">0</span> <span>-</span> <span className="medium_chance" >0</span>
                          </>
                        }
                      </div>
                      {game.away_player} ({game.away_team_name})
                    </li>)
                })
              }
            </ul>
          }
        </div>
        {data.playerVsPlayer &&
          <div className="pages">
            <ul>
              <Pagination
                activePage={activePage}
                itemsCountPerPage={5}
                totalItemsCount={(data.playerVsPlayer.length-5)}
                pageRangeDisplayed={5}
                onChange={handlePage}
              />
            </ul>
          </div>
        }
      </Collapse>
    </Card>
  )
}

export default ToHead
