import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { usePrevious } from '../../App/UsePrevious';

const CardScore = ({ home, away }) => {

  const prevAwayScore = usePrevious(away, 0)
  const prevHomeScore = usePrevious(home, 0)

  return (
    <>
      {home &&
        <>
          <span className='score'>{home > prevHomeScore ? home : prevHomeScore}</span>
          <span className='divisor'>-</span>
          {away != '' ?
            <span className='score'>{away > prevAwayScore ? away : prevAwayScore}</span> :
            <span className='score'>0</span>}
        </>}
    </>
  ) 
}

export default CardScore
